import React from "react";
import {Tabs} from "@nike/epic-react-ui";
import { TabPanel } from "react-tabs";
import {ToastContainer} from "react-toastify";
import Search from "./Search";
import Retry from "./Retry";
import Dashboard from "./Dashboard";

const Home = () => {
    return (
        <div style={{paddingTop :'65px'}}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Tabs
                className="tabs bg-color-tabs"
                selectedTabPanelClassName="activeTabPanel"
                tabs={["Node Intg Platform Search","Node Intg Platform Retry","Dashboard"]}
            >
                <TabPanel className="tabPanel bg-color padding-tabs">
                    <Search/>
                </TabPanel>
                <TabPanel className="tabPanel bg-color padding-tabs">
                    <Retry/>
                </TabPanel>
                <TabPanel className="tabPanel bg-color padding-tabs">
                    <Dashboard/>
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default Home;
