import {
    DialogStyled,
    NikeDesignSystemProvider,
    Box,
    Skeleton, Stack
} from "@nike/nike-design-system-components";
import {Button, Modal, PageSpinner} from "@nike/epic-react-ui";
import React, {useEffect, useState} from "react";
import "./styles/details.css"
import moment from "moment"
import {RetryService} from "../services/service.retry";
import {toast} from "react-toastify";
import NODE_INTG_MESSAGES from "../constants/app.constants";
import API_INTERFACE_URI from "../constants/uri.constants";
import config from "../config/config.js";
import MessageDetails from "./MessageDetails";

const SingleRow = (props) => {
    const[showModal, setShowModal] = useState(false)
    const[requestSuccess, setRequestSuccess] = useState(false)
    const[showConfirmModal, setShowConfirmModal] = useState(false)
    const[showSpinner, setShowSpinner] = useState(false)

    useEffect( ()=>{
        setRequestSuccess(true)
    }, []);

    const retrySelected = () => {
        setShowConfirmModal(true);
        setShowModal(false);
    }

    const handleModalState = (isOpen) => {
        setShowModal(isOpen);
    }

    const handleSpinnerState = (isSpinnerOpen) => {
        setShowSpinner(isSpinnerOpen);
    }

    const buildAndOpenSplunkLogURI = (messageId, timeStamp) => {
        var splunkIndex = config.isProd ? NODE_INTG_MESSAGES.SPLUNK_INDEX_PROD : NODE_INTG_MESSAGES.SPLUNK_INDEX_NON_PROD
        var eventTimeStamp = new Date(moment(timeStamp).subtract(1, 'day').format("yyyy-MM-DD")).getTime()/1000;
        window.open(API_INTERFACE_URI.SPLUNK_LOG_BASE_URI.concat(` index=${splunkIndex}`).concat(` sourcetype="${NODE_INTG_MESSAGES.SPLUNK_SOURCE_TYPE}"`).concat(` messageId="${messageId}"`).concat(` geo="${config.geo}"`).concat(` env="${config.appEnv}"`).concat(`&display.page.search.mode=verbose&dispatch.sample_ratio=1&earliest=${eventTimeStamp}&latest=now`), '_blank', 'noopener,noreferrer')
    }

    const retrySelectedApi = async () => {
        let payload = {
            messageId: props.data.messageId,
            messageType: props.data.messageType,
            startDate: props.startDate,
            endDate: props.endDate
        };
        setShowModal(false);
        setShowConfirmModal(false);
        setShowSpinner(true);
        await RetryService.retryAction([payload]).then(
            (response) => {
                setShowSpinner(false);
                props.handleState([props.data.messageId]);
                toast.success(NODE_INTG_MESSAGES.MESSAGE_RETRY_SUCCESS, {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch(
            (response) => {
                setShowModal(false);
                toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
            });
    }

    return (
        <div>
            <NikeDesignSystemProvider>
                <span>
                    <h6>
                      
                        {
                        props.data.messageId.length > 37 ?
                           <a className={'anchor_tag'} onClick={() =>  setShowModal(true)} href="#" title={props.data.messageId} alt={props.data.messageId} > {props.data.messageId.substring(0, 36)}...</a> 
                           : <a className={'anchor_tag'} onClick={() =>  setShowModal(true)} href="#" title={props.data.messageId} alt={props.data.messageId} > {props.data.messageId}</a> 
                         }
                        
                       
                    </h6>
                </span>
            <>
                <Modal
                    onClose={() => setShowConfirmModal(false)}
                    closeButton
                    swoosh
                    show={showConfirmModal}
                    test-id="confirm-model"
                >
                    <Modal.Title>
                        <h3 style={{color:'#000000'}}>Retry Selected Message ?</h3>
                        <h2 style={{color:'#000000'}}>Selected Message will be removed from the search results after retry</h2>
                    </Modal.Title>
                    <Modal.Actions>
                        <Button
                            small
                            className="button"
                            onClick={() => {setShowConfirmModal(false);setShowModal(true)}}
                            test-id="modal-no-button"
                            theme={{
                                primary: "#000000",
                                secondary: "#ffffff",
                            }}
                        >
                            No
                        </Button>
                        <Button
                            small
                            className="button"
                            test-id="modal-yes-button"
                            onClick={retrySelectedApi}
                            theme={{
                                primary: "#228B22",
                                secondary: "#ffffff",
                            }}
                        >
                            Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            </>
            <PageSpinner show={showSpinner} />
            <div>
                <DialogStyled
                    onClose={() => setShowModal(false)}
                    isOpen={showModal}
                    appearance="fullscreen"
                    title=""
                    dialogContainerCompProps={{style:{width: '90%'}}}
                >
                    <Box maxWidth="100%">
                        { !requestSuccess ?
                            <Stack gapXs="m" display="false">
                                <Skeleton width="100%" height="200px" borderRadius="s" />
                                <Skeleton width="50%" height="20px" />
                                <Skeleton width="50%" height="20px" />
                                <Skeleton width="50%" height="20px" />
                            </Stack> :
                            <div>
                                <div className="details-section" style={{paddingBottom:'1%'}}>
                                    <span className="details-field" style={{width:'44%'}}>
                                    <h2>Message Details</h2>
                                    </span>
                                    <span className="details-field" style={{width:'11%'}}>
                                        <Button
                                            small="true"
                                            className="button"
                                            onClick={() => setShowModal(false)}
                                        >
                                        Close  
                                        
                                        </Button>
                                    </span>
                                    <span className="details-field" style={{width:'11%'}}>
                                        <Button
                                            small="true"
                                            className="button"
                                            onClick={retrySelected}
                                            disabled={!props.data.retryable}
                                        >
                                        Retry
                                        </Button>
                                    </span>
                                    <span className="details-field" style={{width:'11%'}}>
                                        <Button
                                            small="true"
                                            className="button"
                                            onClick={() => window.open(`${API_INTERFACE_URI.SIGNAL_FX_APM_TRACE}${props.data.traceId}`, '_blank', 'noopener,noreferrer')}
                                        >
                                        Trace
                                        </Button>
                                    </span>
                                    <span className="details-field" style={{width:'11%'}}>
                                        <Button
                                            small="true"
                                            className="button"
                                            onClick={() => buildAndOpenSplunkLogURI(props.data.messageId.split("_")[0], props.data.eventTimeStamp)}
                                        >
                                        Logs
                                        </Button>
                                    </span>
                                </div>
                                <MessageDetails data={props.data} businessValue={props.businessValue} handleModalState={handleModalState} fromUI={true} handleSpinnerState={handleSpinnerState}/>
                            </div>
                        }
                    </Box>
                </DialogStyled>
                </div>
            </NikeDesignSystemProvider>
        </div>
    )
};

export default SingleRow;


