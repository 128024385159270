export const tableCol = [
    {
        Header: 'MessageType',
        accessor: 'messageType',
        columnCount: 4
    },
    {
        Header: 'Triggered By',
        accessor: 'triggeredBy',
        columnCount: 4
    },
    {
        Header: 'Triggered TimeStamp',
        accessor: 'updatedTimeStamp',
        columnCount: 2
    },
    {
        Header: 'Status',
        accessor: 'status',
        columnCount: 1
    },
   
]


export const reportData =  [
    {
        "messageType": "WEIGHTSMEASURESDDM",
        "user": "shashankshekhar.sinha@nike.com",
        "status": "COMPLETED",
        "triggeredTimeStamp": "2024-01-02 05:38:15.000"
    },
    {
        "messageType": "WEIGHTSMEASURESDDM",
        "user": "shashankshekhar.sinha@nike.com",
        "status": "COMPLETED",
        "triggeredTimeStamp": "2024-01-02 05:38:15.000"
    },
    {
        "messageType": "WEIGHTSMEASURESDDM",
        "user": "vasudev.tyagi@nike.com",
        "status": "COMPLETED",
        "triggeredTimeStamp": "2024-01-02 05:38:15.000"
    },
    {
        "messageType": "WEIGHTSMEASURESDDM",
        "user": "manideep.m@nike.com",
        "status": "COMPLETED",
        "triggeredTimeStamp": "2024-01-02 05:38:15.000"
    },
    {
        "messageType": "WEIGHTSMEASURESDDM",
        "user": "manideep.m@nike.com",
        "status": "COMPLETED",
        "triggeredTimeStamp": "2024-01-02 05:38:15.000"
    }
]


export const jobApiResponse = {
    
    "jobReportList": [{
        "messageType": "WEIGHTSMEASURESDDM",
        "id": "06ef4f34-a599-11ee-b853-29bfef27e9f5",
        "status": "COMPLETED",
        "triggeredTimeStamp": "2024-01-02 05:38:15.000"
    },
    {
        "messageType": "WEIGHTSMEASURESDDM",
        "id": "47f7c525-a352-11ee-8089-adf0440ca526",
        "status": "COMPLETED",
        "triggeredTimeStamp": "2024-01-02 05:38:15.000"
    },
    {
        "messageType": "WEIGHTSMEASURESDDM",
        "id": "5a446cf3-a357-11ee-89eb-dfe09fc7837a",
        "status": "COMPLETED",
        "triggeredTimeStamp": "2024-01-02 05:38:15.000"
    },
    {
        "messageType": "WEIGHTSMEASURESDDM",
        "id": "793b50be-a3c1-11ee-92a8-1f7ae18d71d7",
        "status": "COMPLETED",
        "triggeredTimeStamp": "2024-01-02 05:38:15.000"
    },
    {
        "messageType": "WEIGHTSMEASURESDDM",
        "id": "86a56836-a3b1-11ee-8ff6-8393cff10a7c",
        "status": "COMPLETED",
        "triggeredTimeStamp": "2024-01-02 05:38:15.000"
    }],
    "lastEvaluatedKey": "ssaas"
}
