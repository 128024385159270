import config from "../config/config.js";

const API_INTERFACE_URI = {
    /**
     * Search service endpoints
     */
    MESSAGE_TYPE_PATH: `${config.searchServiceUrl}/node/intgpltfm/messagetypes/v1`,

    MESSAGE_NODE_PATH: `${config.searchServiceUrl}/node/intgpltfm/messagenodes/v1`,

    FAILURE_COUNT_PATH: `${config.searchServiceUrl}/node/intgpltfm/countfailedmessages/v1`,

    MESSAGE_SEARCH_PATH: `${config.searchServiceUrl}/node/intgpltfm/search/v1`,

    FAILED_MESSAGE_SELECTIVE_SEARCH_PATH: `${config.searchServiceUrl}/node/intgpltfm/searchselectivefailed/v1`,

    FAILED_MESSAGE_SELECTIVE_RETRY_PATH: `${config.searchServiceUrl}/node/intgpltfm/retryselectivefailed/v1`,
    SUCCESS_MESSAGE_RETRY_PATH: `${config.searchServiceUrl}/node/intgpltfm/retry/success/v1`,

    /**
     * Retry service endpoints
     */
    RETRY_ACTION_PATH: `${config.searchServiceUrl}/node/intgpltfm/retry/v1`,

    JOB_SEARCH_PATH: `${config.searchServiceUrl}/node/intgpltfm/search/job/v1`,

    JOB_DOWNLOAD_PATH: `${config.searchServiceUrl}/node/intgpltfm/download/job/v1`,

    BULK_RETRY_ACTION_PATH: `${config.searchServiceUrl}/node/intgpltfm/bulkretry/v1`,

    /**
     * Pre-Signed URL service endpoints
     */
    PRE_SIGNED_PATH: `${config.searchServiceUrl}/node/intgpltfm/presignedurl/v1`,

    /**
     * SignalFx Trace Base URI
     */
    SIGNAL_FX_APM_TRACE: "https://app.signalfx.com/#/apm/traces/",


    /**
     * Splunk Logs Base URI
     */
    SPLUNK_LOG_BASE_URI: "https://nike.splunkcloud.com/en-GB/app/nike_search/search?q=search"

};

export default API_INTERFACE_URI;
