import merge from "deepmerge";

const base = { 
    appFullName: "Integration Platform",
    appName: "INTGPLTFM",
    description: "Node Management Integration Platform ",
    isProd: false,
  },

  
na_dev = merge(base, {
    //searchServiceUrl: "http://localhost:8080",
    geo: "na",
    appEnv: "dev",
    messageSearchLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_search_dashboard_nonprod?form.current_environment=dev&form.current_geo=na",
    servicesLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_services_dashboard_nonprod?form.current_environment=dev&form.current_geo=na",
    statisticsLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_stats_dashboard_nonprod?form.current_environment=dev&form.current_geo=na",
    overallPerformanceLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_performance_dashboard_nonprod?form.current_environment=dev&form.current_geo=na",
    integrationPerformanceLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_messagetype_performance_dashboard_nonprod?form.current_environment=dev&form.current_geo=na",
    configurationDetailsLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_configuration_details_nonprod?form.current_environment=dev&form.current_geo=na",
    searchServiceUrl: "https://api-na-node-intg-dev.nodemanagement-test.nikecloud.com",
    signalFxDashboardLink: "https://app.signalfx.com/#/dashboard/FwttvhvAYCo?groupId=FwttvhvAYCk&configId=FwttvhvAYCs&sources%5B%5D=platform:NIS&startTime=-31d&endTime=Now&variables%5B%5D=Environment%3Denv:%5B%22dev%22%5D&variables%5B%5D=Deployment%20Geo%3DdeploymentGeo:%5B%22na%22%5D",
    oAuth: {
        clientId: "nike.nodemgmt.intg-status-ui", 
        redirectUri: window.location.origin,
        scopes: [
            "node_management:message.status::read: profile openid email",
        ],
    },
}),
na_test = merge(base,  {
    geo: "na",
    appEnv: "test",
    messageSearchLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_search_dashboard_nonprod?form.current_environment=test&form.current_geo=na",
    servicesLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_services_dashboard_nonprod?form.current_environment=test&form.current_geo=na",
    statisticsLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_stats_dashboard_nonprod?form.current_environment=test&form.current_geo=na",
    overallPerformanceLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_performance_dashboard_nonprod?form.current_environment=test&form.current_geo=na",
    integrationPerformanceLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_messagetype_performance_dashboard_nonprod?form.current_environment=test&form.current_geo=na",
    configurationDetailsLink: "https://nike.splunkcloud.com/en-GB/app/NIKE_NIS/nis_configuration_details_nonprod?form.current_environment=test&form.current_geo=na",
    searchServiceUrl: "https://api-na-node-intg-test.nodemanagement-test.nikecloud.com",
    signalFxDashboardLink: "https://app.signalfx.com/#/dashboard/FwttvhvAYCo?groupId=FwttvhvAYCk&configId=FwttvhvAYCs&sources%5B%5D=platform:NIS&startTime=-31d&endTime=Now&variables%5B%5D=Environment%3Denv:%5B%22test%22%5D&variables%5B%5D=Deployment%20Geo%3DdeploymentGeo:%5B%22na%22%5D",
    oAuth: {
        clientId: "nike.nodemgmt.intg-status-ui",
        redirectUri: window.location.origin,
        scopes: [
            "node_management:message.status::read: profile openid email",
        ],
    },
})


let env = na_dev;

if (window.location.origin.includes("localhost")) {
    env = na_dev;
} else if (window.location.origin.includes("na-node-pltfm-status-ui-dev.nodemanagement-test.nikecloud.com")) {
    env = na_dev; 
} else if (window.location.origin.includes("na-node-pltfm-status-ui-test.nodemanagement-test.nikecloud.com")) {
    env = na_test;
}
  

const config = env;
export default config;
