import React, {useState} from "react";
import TableCard from "../component/TableCard";
import 'bootstrap/dist/css/bootstrap.min.css';
import MultipleMessageRetry from "./MultipleMessageRetry";
import SuccessMessageRetry from "./SuccessMessageRetry";
import { Repeat,Replay,MultiSelect,Report} from "@nike/nike-design-system-icons";
import BulkRetryApp from "./BulkRetryApp";
import {
  NikeDesignSystemProvider
} from "@nike/nike-design-system-components";
import JobReport from "./JobReport";

const Retry = () => {
    const [selectedCriteria, setSelectedCriteria] = useState('')
    const [backButton, setBackButton] = useState(false)

    const selectedOption=(option) => {
        setSelectedCriteria(option);
        setBackButton(false)
    }
    const backButtonHandler = (isPressed) => {
        setBackButton(isPressed);
    }
    

    const retryMap = [{
        name: "Bulk Retry",
        description: "Retry all the failed messages for different message types",
        icon: Repeat
     },
      {
        name: "Selective Retry",
        description: "Selectively retry all messages types",
        icon: MultiSelect
      },
      /*
      {
      name: "Successful Message Retry",
      description: "Selectively retry successful message for single message type",
      icon: Replay
      }
      
      ,{
        name: "Job Report",
        description: "Detail Report for successful retried messages",
        icon: Report
      }
      */
    ]



  return <div id="modal-root" className="bg-height">
        <NikeDesignSystemProvider>
    {selectedCriteria === '' || backButton ? (
      <TableCard retryMap={retryMap} selectedOption={selectedOption}/>
    ) : selectedCriteria === 'Selective Retry' ? (
      <MultipleMessageRetry backButtonHandler={backButtonHandler} />
    ) : selectedCriteria === 'Bulk Retry' ? (
      <BulkRetryApp backButtonHandler={backButtonHandler} />
    ): selectedCriteria === 'Successful Message Retry'? (
      <SuccessMessageRetry backButtonHandler={backButtonHandler} />) :
      (<JobReport backButtonHandler={backButtonHandler} />)
      }
      </NikeDesignSystemProvider>
   </div>

}

export default Retry;
