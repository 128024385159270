import config from "../config/config.js";
import "./styles/dashboard.css";
import React from "react";
import {
   TableContainer, Tbody, Tr, Td
} from "@nike/epic-react-ui";

const Dashboards = () => {
  return (
      <div className="bg-height">
          <div className="row dashboard-section dashboard-display" >
              <div  className="col-6">
                  <TableContainer className="table table-striped table-hover">
                      <Tbody>
                          <Tr>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                                  <span className="epic-title-4 table-label dashboard-display"><h4>Splunk</h4></span>
                              </Td>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                                  <span className="epic-title-4 table-label dashboard-display"><h4>SignalFx</h4></span>
                              </Td>
                          </Tr>
                          <Tr>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                                  <a href = {config.messageSearchLink} target="_blank" rel="noreferrer" className="a-hyperlink">
                                      <u className="dashboard-display">Message Search Dashboard</u>
                                  </a>
                              </Td>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                                  <a href = {config.signalFxDashboardLink} target="_blank" rel="noreferrer" className="a-hyperlink">
                                      <u className="dashboard-display">SignalFx Dashboard</u>
                                  </a>
                              </Td>
                          </Tr>
                          <Tr>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                                  <a href = {config.servicesLink} target="_blank" rel="noreferrer" className="a-hyperlink">
                                      <u className="dashboard-display">Services Dashboard</u>
                                  </a>
                              </Td>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                              </Td>
                          </Tr>
                          <Tr>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                                  <a href = {config.statisticsLink} target="_blank" rel="noreferrer" className="a-hyperlink">
                                      <u className="dashboard-display">Statistics Dashboard</u>
                                  </a>
                              </Td>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                              </Td>
                          </Tr>
                          <Tr>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                                  <a href = {config.overallPerformanceLink} target="_blank" rel="noreferrer" className="a-hyperlink">
                                      <u className="dashboard-display">Overall Performance Dashboard</u>
                                  </a>
                              </Td>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                              </Td>
                          </Tr>
                          <Tr>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                                  <a href = {config.integrationPerformanceLink} target="_blank" rel="noreferrer" className="a-hyperlink">
                                      <u className="dashboard-display">Integration Performance Dashboard</u>
                                  </a>
                              </Td>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                              </Td>
                          </Tr>
                          <Tr>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                                  <a href = {config.configurationDetailsLink} target="_blank" rel="noreferrer" className="a-hyperlink">
                                      <u className="dashboard-display">Configuration Details Dashboard</u>
                                  </a>
                              </Td>
                              <Td className="col-3 td-compact-table-cell-dashboard">
                              </Td>
                          </Tr>
                      </Tbody>
                  </TableContainer>
              </div>
          </div>
      </div>
  )
}

export default Dashboards;
