import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import style  from '../containers/styles/Card.module.css';



const Card = (props) => {

    const Icon = props.icon;  
    return (
            
            <div className="col-sm-2" >
            <div className={`card ${style.customCard} h-100`} bsprefix="customCard" onClick={() => props.selectedOption(props.name)}>
                        <div className = "card-header"> {props.name} </div> 
                <div className={`card-body ${style.cardBody}`} style={{ color:props.color}}>
                    <span className='eds-color--text-default'><Icon name={props.icon} size="l" /></span>
                            </div>
                        <div className="card-footer bg-transparent">{props.description}</div>
                </div>
            </div>
        
    );

}

export default Card;