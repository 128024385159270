import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service.base";
import { globalHeaders } from "../utilities/http";

function retryAction(payload) {
    return baseService.post(
        API_INTERFACE_URI.RETRY_ACTION_PATH,
        globalHeaders,
        payload
    );
}

function bulkRetryAction(payload) {
    return baseService.post(
        API_INTERFACE_URI.BULK_RETRY_ACTION_PATH,
        globalHeaders,
        payload
    );
}

function selectedRetryAction(payload) {
    return baseService.post(
        API_INTERFACE_URI.FAILED_MESSAGE_SELECTIVE_RETRY_PATH,
        globalHeaders,
        payload
    );
}

function successMessageRetry(payload) {
    return baseService.post(
        API_INTERFACE_URI.SUCCESS_MESSAGE_RETRY_PATH,
        globalHeaders,
        payload
    );
}

export const RetryService = {
    retryAction,
    bulkRetryAction,
    selectedRetryAction,
    successMessageRetry,
}
