import {Replay} from "@nike/nike-design-system-icons";
import {ButtonStyled} from "@nike/nike-design-system-components";
import { Button, Modal, PageSpinner } from "@nike/epic-react-ui";
import React, {useEffect, useState} from "react";
import {RetryService} from "../services/service.retry";
import { toast } from "react-toastify";
import NODE_INTG_MESSAGES from "../constants/app.constants";

const MultipleRows = (props) => {
    const[disableButton, setDisableButton] = useState(false)
    const[showModal, setShowModal] = useState(false)
    const[showSpinner, setShowSpinner] = useState(false)


    useEffect(() => {
        props.selectedMessageIds.length === 0 ? setDisableButton(true) : setDisableButton(false)
    },[props.selectedMessageIds.length]);

    const bulkRetrySelected = async () => {
        let payload = []

        props.selectedMessageIds.forEach(messageId => {
            let messageDetail = {
                messageId: messageId,
                messageType: props.messageType,
                startDate: props.startDate,
                endDate: props.endDate
            };
            payload.push(messageDetail);
        })
        setDisableButton(true);
        setShowModal(false);
        setShowSpinner(true);
        await RetryService.retryAction(payload).then(
            (response) => {
                setShowSpinner(false);
                setDisableButton(false);
                props.handleState(props.selectedMessageIds);
                props.handleSelectedMessageIdsState();
                toast.success(NODE_INTG_MESSAGES.BULK_MESSAGE_RETRY_SUCCESS, {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }).catch(
            (response) => {
                toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
            });
    }

    return (
        <div>
            <ButtonStyled
                children="Retry Selected"
                size="medium"
                disabled={disableButton}
                Icon={<Replay />}
                onClick={() => setShowModal(true)}
            />
            <>
                <Modal
                    onClose={() => setShowModal(false)}
                    closeButton
                    swoosh
                    show={showModal}
                    test-id="confirm-model"
                >
                    <Modal.Title>
                        <h2 style={{color:'#000000'}}>Retry Selected Messages ?</h2>
                    </Modal.Title>
                    <Modal.Actions>
                        <Button
                            small
                            className="button"
                            onClick={() => setShowModal(false)}
                            test-id="modal-no-button"
                            theme={{
                                primary: "#000000",
                                secondary: "#ffffff",
                            }}
                        >
                            No
                        </Button>
                        <Button
                            small
                            className="button"
                            test-id="modal-yes-button"
                            onClick={bulkRetrySelected}
                            theme={{
                                primary: "#228B22",
                                secondary: "#ffffff",
                            }}
                        >
                            Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            </>
            <PageSpinner show={showSpinner} />
        </div>
    )
};

export default MultipleRows


