import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service.base";
import { globalHeaders } from "../utilities/http";

function getMessageTypeInformation(queryParams) {
    let url = API_INTERFACE_URI.MESSAGE_TYPE_PATH;
    if (queryParams !== undefined) url = url + "?" + queryParams;
    return baseService.get(url, globalHeaders);
}

function getMessageNodeInformation(queryParams) {
    let url = API_INTERFACE_URI.MESSAGE_NODE_PATH;
    if (queryParams !== undefined) url = url + "?" + queryParams;
    return baseService.get(url, globalHeaders);
}

function preSignedUrlMethod(payload) {
    return baseService.post(
        API_INTERFACE_URI.PRE_SIGNED_PATH,
        globalHeaders,
        payload
    );
}

function downloadMessageWithPreSignedURL(url){
    return baseService.getWithURL(url);
}

export const CommonService = {
    preSignedUrlMethod,
    getMessageTypeInformation,
    getMessageNodeInformation,
    downloadMessageWithPreSignedURL
}
