import {Replay} from "@nike/nike-design-system-icons";
import {ButtonStyled} from "@nike/nike-design-system-components";
import {Button, Modal, PageSpinner} from "@nike/epic-react-ui";
import React, { useState } from "react";
import moment from "moment";
import {RetryService} from "../services/service.retry";
import {toast} from "react-toastify";
import NODE_INTG_MESSAGES from "../constants/app.constants";

const SelectedRetry = (props) => {
    const[disableButton, setDisableButton] = useState(false)
    const[showModal, setShowModal] = useState(false)
    const[showSpinner, setShowSpinner] = useState(false)

    const bulkRetrySelected = async () => {
        setShowModal(false);
        setShowSpinner(true);
        setDisableButton(true);
        let payload = {
            fromTimeStamp : moment(props.fromTimeStamp).format("yyyy-MM-DD HH:mm:ss"),
            toTimeStamp : moment(props.toTimeStamp).add(1, 'day').format("yyyy-MM-DD HH:mm:ss"),
            messageTypeList: props.selectedMessageTypes
        };
        await RetryService.bulkRetryAction(payload).then(
            (response) => {
                setShowSpinner(false);
                setDisableButton(false);
                props.handleState(props.selectedMessageTypes);
                toast.success(NODE_INTG_MESSAGES.MULTIPLE_MESSAGES_RETRY_SUCCESS, {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch(
            (response) => {
                toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
            });
    }

    return (
        <div>
            <ButtonStyled
                children="Retry Selected"
                size="medium"
                disabled={disableButton}
                Icon={<Replay />}
                onClick={() => setShowModal(true)}
            />
            <PageSpinner show={showSpinner} />
            <>
            <Modal
                onClose={() => setShowModal(false)}
                closeButton
                swoosh
                show={showModal}
                test-id="confirm-model"
            >
                <Modal.Title>
                    <h2 style={{color:'#000000'}}>Retry Selected Interface Types ?</h2>
                </Modal.Title>
                <Modal.Actions>
                    <Button
                        small
                        className="button"
                        onClick={() => setShowModal(false)}
                        test-id="modal-no-button"
                        theme={{
                            primary: "#000000",
                            secondary: "#ffffff",
                        }}
                    >
                        No
                    </Button>
                    <Button
                        small
                        className="button"
                        test-id="modal-yes-button"
                        onClick={() => bulkRetrySelected()}
                        theme={{
                            primary: "#228B22",
                            secondary: "#ffffff",
                        }}
                    >
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
        </div>
    )
};

export default SelectedRetry;


