import React, {useEffect, useState} from "react";
import {
    DatePickerStyled,
    NikeDesignSystemProvider,
    Skeleton, Box, Stack
} from "@nike/nike-design-system-components";
import moment from "moment";

import { IconButton, Table, PageSpinner} from "@nike/epic-react-ui";
import {SearchService} from "../services/service.search";
import SelectedRetry from "./SelectedRetry";
import {toast} from "react-toastify";
import NODE_INTG_MESSAGES from "../constants/app.constants";

import '../containers/styles/BulkRetryApp.css';

const  INITIAL_NUMBER_OF_DAYS_FOR_SEARCH = 7;
const  MAX_ALLOWED_DAYS_FOR_SEARCH = 90;

const BulkRetryApp = (props) => {

    const [results, setResults] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [isToDateError, setIsToDateError] = useState(false);
    const [isFromDateError, setIsFromDateError] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [searchTriggered, setSearchTriggered] = useState(false);
    const [resultsLoaded, setResultsLoaded] = useState(false);
    const [disableMessage, setDisableMessage] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const searchTableColumns = [ 
        {
            Header: 'Message Type',
            accessor: 'messageType'
        },
        {
            Header: 'Failed Message Count',
            accessor: 'messageCount',
            disableGlobalFilter: true,
            disableSortBy: true,
        }
    ]

    useEffect( () => {
        //setFromDate(moment().subtract(1, 'month').format("yyyy-MM-DD"))
        //setToDate(moment().format("yyyy-MM-DD"))
        setFromDate(moment().subtract(INITIAL_NUMBER_OF_DAYS_FOR_SEARCH, 'day').format("yyyy-MM-DD"))
        setToDate(moment().format("yyyy-MM-DD"))
    }, []);

    useEffect( () => {
        let tempToDate = moment(toDate);
        let tempFromDate = moment(fromDate);
        if(!tempToDate.isValid()){
            setIsToDateError(true);
            setDisableButton(true)
        }else{
            setIsToDateError(false);
        }
        if(!tempFromDate.isValid()){
            setIsFromDateError(true);
            setDisableButton(true)
        }else{
            setIsFromDateError(false);
        }
        if(tempToDate.isValid() && tempFromDate.isValid()){
            setDisableButton(false)
        }
        validateDate(tempToDate, tempFromDate);
    }, [toDate, fromDate]);

    const validateDate = (toDateValue, fromDateValue) => {
        if((fromDateValue === '' && toDateValue !== '') || (toDateValue < fromDateValue) || parseInt((new Date(toDateValue) - new Date(fromDateValue)) / (1000 * 60 * 60 * 24), 10) > MAX_ALLOWED_DAYS_FOR_SEARCH ){
            setIsToDateError(true);
            setIsFromDateError(true);
            setDisableButton(true);
        }else{
            setIsToDateError(false);
            setIsFromDateError(false);
            setDisableButton(false);
        }
    }

    const resetState = () => {
        setIsToDateError(false);
        setIsFromDateError(false);
        setDisableButton(false);
    }

    const triggerSearch = async () => {
        setDisableButton(true)
        setSearchTriggered(true)
        setResultsLoaded(false)
        setShowSpinner(true)
        setDisableMessage(NODE_INTG_MESSAGES.REQUEST_IN_PROGRESS)
        toast.success(NODE_INTG_MESSAGES.MESSAGE_RETRY_TRIGGER_SUCCESS);
        let payload = {
            fromTimeStamp: moment(fromDate).format("yyyy-MM-DD HH:mm:ss"),
            toTimeStamp: moment(toDate).add(1, 'day').format("yyyy-MM-DD HH:mm:ss"),
        };

        await SearchService.failedMessageCount(payload).then(
            (response) => {
                setShowSpinner(false)
                setDisableButton(false)
                setResults(response.status === 200 ? response.data.objects : []);
                if(response.status === 204){
                    toast.error(NODE_INTG_MESSAGES.NO_RESULTS_FOUND);
                }
            }).catch(
            (response) => {
                setShowSpinner(false)
                toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
            });
        setResultsLoaded(true);
        setDisableButton(false)
    }

    const renderRetryButton = (selectedRowIds) => {
        let selectedMessageTypes = []
        for(let count = 0; count < selectedRowIds.selectedFlatRows.length; count ++){
            selectedMessageTypes.push(selectedRowIds.selectedFlatRows[count].original.messageType)
        }
        return <SelectedRetry handleState = {handleState} selectedMessageTypes = {selectedMessageTypes} fromTimeStamp = {fromDate} toTimeStamp={toDate}/>
    }

    const handleState = (selectedMessageList) => {
        let updatedArray = results.filter((result) => !selectedMessageList.includes(result.messageType));
        setResults(updatedArray);
    }

    return (
        <div id="modal-root">
            <NikeDesignSystemProvider>

            <div className="subTitle">Bulk Retry</div>
                <div className="search-section">
                    <span className="search-field-span">
                        <DatePickerStyled
                            name="Retry window from date"
                            id="retry-from-date"
                            label="From"
                            placeholder="From"
                            error={isFromDateError}
                            errorMessage= {"Date Range should be " + MAX_ALLOWED_DAYS_FOR_SEARCH + " days"}
                            autoComplete="off"
                            value={fromDate}
                            shouldUseFallback={false}
                            iconTitle="Calendar Icon"
                            datepickerfallbackprops={{monthPlaceholder:"mm",dayPlaceholder:"dd",yearPlaceholder:"yyyy",a11yMonthLabel:"Month",a11yDayLabel:"Day",a11yYearLabel:"Year",dateSeparator:"/"}}
                            onChange={(event) => {
                                resetState();
                                setFromDate(event.target.value);
                            }}
                        />
                    </span>
                    <span className="search-field-span">
                        <DatePickerStyled
                            name="Retry window to date"
                            id="retry-to-date"
                            label="To"
                            placeholder="To"
                            error={isToDateError}
                            errorMessage= {"Date Range should be " + MAX_ALLOWED_DAYS_FOR_SEARCH + " days"}
                            autoComplete="off"
                            value={toDate}
                            shouldUseFallback={false}
                            iconTitle="Calendar Icon"
                            datepickerfallbackprops={{monthPlaceholder:"mm",dayPlaceholder:"dd",yearPlaceholder:"yyyy",a11yMonthLabel:"Month",a11yDayLabel:"Day",a11yYearLabel:"Year",dateSeparator:"/"}}
                            onChange={(event) => {
                                resetState();
                                setToDate(event.target.value);
                            }}
                        />
                    </span>
                </div>  
                    <div className="searchField" style={{paddingTop:'1%'}}>
                        <span style={{ marginRight:'1%'}}> 
                            <IconButton
                                type="Search"
                                iconSize="m"
                                disabled={disableButton}
                                onClick={triggerSearch}
                                disabledTip={{
                                    message: disableMessage,
                                    position: "right",
                                    size: "medium",
                                }}
                                theme={{
                                    primary: !disableButton ? "#000000" : "#e5e5e5",
                                    secondary: !disableButton ? "#ffffff" : "#757575",
                                }}
                            > 
                        Search
                                </IconButton>
                        </span>       
                        <span>    
                            <IconButton
                                type="CaretLeft"
                                iconSize="m"
                                onClick={()=>props.backButtonHandler(true)}
                                disabledTip={{
                                    message: disableMessage,
                                    position: "right",
                                    size: "medium",
                                }} 
                            >
                        Back
                                </IconButton>  
                            </span>     
                    </div>
                <PageSpinner show={showSpinner} />
                <div style={{display:'flex', justifyContent:'center', paddingLeft:'5%', paddingRight:'5%'}}>
                    <Box padding="l" maxWidth="70%" style={{ width: '100%'}}>
                        { searchTriggered ?
                            !resultsLoaded ?
                                <Stack gapXs="m" display="false">
                                    <Skeleton width="100%" height="200px" borderRadius="s" />
                                    <Skeleton width="50%" height="20px" />
                                    <Skeleton width="50%" height="20px" />
                                    <Skeleton width="50%" height="20px" />
                                </Stack>:
                                results.length !== 0 ?
                                <div>
                                    <div className="result-section result-section-padding">
                                        <span className="epic-title-3 table-label">
                                        Search Results
                                        </span>
                                    </div>
                                    <div className="result-section result-section-padding">
                                        <span className="epic-title-3">
                                            <div style={{ width: '100%'}}>
                                            <Table
                                                BulkSelectRowAside={(selectedRowIds) => renderRetryButton(selectedRowIds)}
                                                columns={searchTableColumns}
                                                data={results}
                                                isCompact
                                                visibleRows={6}
                                                withSelectableRows
                                            />
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                :
                                <div/>
                            :<div/>
                        }
                    </Box>
                </div>
            </NikeDesignSystemProvider>
        </div>
    )

}

export default BulkRetryApp;
