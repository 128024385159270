import '../App.css';
import React, { useEffect, useState } from "react";
import { AegisClient, useAuth } from "@nike/aegis-auth-react";
import { Button, PageSpinner } from "@nike/epic-react-ui";
import { setGlobalHeader } from "../utilities/http";
import { Route, Routes } from "react-router-dom";
import config from "../config/config.js";
import Home from "../containers/Home";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StatusMessage from "../containers/StatusMessage";

console.log("config.oAuth :",  config.oAuth)
const client = new AegisClient({
    qa: !config.isProd,
    ...config.oAuth,
});

function App() {
    console.log("config.oAuth1 :",  config.oAuth)
  //const history = useHistory();
  const { loginComplete, isLoggedIn } = useAuth(client);
  const [userToken, setUserToken] = useState();
  //const [displayLogout, setDisplayLogout] = useState(true);

  useEffect(() => {
    function handleLogin({ accessToken }) {
        setGlobalHeader("Authorization", `Bearer ${accessToken.accessToken}`);
                setUserToken(accessToken.accessToken);
        //setDisplayLogout(true);
    } 

    client.on("login:success", handleLogin);
    client.on("renew:access:success", handleLogin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loginComplete || !userToken) return <PageSpinner show={true} />;
  if (!isLoggedIn) return <LoggedOut />;

  /*function handleLogout() {
        setGlobalHeader("Authorization", null);
        clearLogin();
        client.logout();
        setDisplayLogout(false);
        history.push("/logout");
  }*/

  return (
      <div className="container-fluid">
          <Header></Header>
          <div className="component-main">
              <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
              />
              <Routes>
                  <Route exact path="/" element={<Home/>} ></Route>
                  <Route path="/messagedetails" element={<StatusMessage/>}></Route>
                  <Route path="/logout" element={<LoggedOut/>}></Route>
                  <Route element={<Home/>}></Route>
              </Routes>
          </div>
          <div>
              <Footer></Footer>
          </div>
      </div>
  );
}

function LoggedOut() {
    return (
        <div style={{ margin: "50px" }}>
            <div style={{ marginBottom: "20px" }}>
        <span>
          You have been logged out from application.
        </span>
                <p>
          <span >
            Close the browser to log out from Okta session.
          </span>
                </p>
            </div>
            <Button onClick={(event) => (window.location.href = "/")}>Login</Button>
        </div>
    );
}

export default App;
