const NODE_INTG_MESSAGES = {
    /**
     * Messages to display on the UI for Node Intg Platform.
     */
    MULTIPLE_DESTINATIONS_RETRY_SUCCESS: "Selected Destinations retry successfull. Please check the status after few minutes.",
    MULTIPLE_MESSAGES_RETRY_SUCCESS: "Selected Messages retry successfull. Please check the status after few minutes.",
    BULK_MESSAGE_RETRY_SUCCESS: "Bulk Message Retry Successfull. Please check the status after few minutes",
    MESSAGE_RETRY_SUCCESS: "Message Retry Successfull. Please check the status after few minutes",
    REQUEST_IN_PROGRESS: "Please wait until the request is completed",
    MESSAGE_SEARCH_TRIGGER_SUCCESS: "Messages Search Triggered for selected Search criteria",
    SUCCESS_MESSAGE_RETRY_TRIGGER_SUCCESS : "Messages Retry Triggered for selected criteria",
    MESSAGE_RETRY_TRIGGER_SUCCESS: "Failed Messages Search Triggered",
    NO_RESULTS_FOUND: "No Results found with the selected search criteria",
    INVALID_INPUT: "Please correct the search criteria values",
    INVALID_INPUT_RETRY: "Please correct the search criteria for retry",
    TOAST_ERROR_FILE_DOWNLOAD: "Error while downloading the file. Please contact support team.",
    TOAST_ERROR_MESSAGE: "Exception occured. Please contanct support team.",
    SPLUNK_INDEX_NON_PROD: "np-dockerlogs",
    SPLUNK_INDEX_PROD: "dockerlogs",
    SPLUNK_SOURCE_TYPE: "log4j:na-node-intg-status-service-*"
};

export default NODE_INTG_MESSAGES;
