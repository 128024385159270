import React, { useState } from "react";
import {
    NikeDesignSystemProvider,
    Typeahead,
    Box,
    Skeleton,
    Stack
} from "@nike/nike-design-system-components";

import { IconButton, PageSpinner, TableContainer, Thead, Tr, Th, Td, Tbody } from "@nike/epic-react-ui";
import { JobSearchService } from "../services/service.jobsearch"
import { CommonService } from "../services/service.common"
import { toast } from "react-toastify";
import style from '../containers/styles/JobReport.module.css'
import  { useEffect,useRef} from "react";
import { tableCol} from "../constants/jobreport.constants";
import NODE_INTG_MESSAGES from "../constants/app.constants";



const JobReport = (props) => {

    const hasMore = useRef(false);
    const [spinner, setShowSpinner] = useState(false);
    const [msgTypeList, setMessageTypeList] = useState([]);
    const [msgType, setMessageType] = useState("");
    const message = useRef("");
    const [disableButton, setDisableButton] = useState(false);
    const [lastElement, setLastElement] = useState(null);
    const [jobReportList, setJobReportList] = useState([]);
    const nextToken = useRef(null);
    const [resultsLoaded, setResultsLoaded] = useState(false);
    const [searchTriggered, setSearchTriggered] = useState(false);



    const triggerDownload = async (msgType, jobId) => {
                
        var query = "messageType=" + msgType + "&jobId=" + jobId;
        
        await JobSearchService.downloadJobDetail(query).then(
            (response) => {
                if (response.status === 200) {

                    const json = JSON.stringify(response.data, null, 2);
                    const fileName = msgType + "_" + jobId;
                    const blob = new Blob([json], { type: "application/json" });
                    const href = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = href;
                    link.download = fileName + ".json";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                }
              
            }
        ).catch(
            (response) => {
                toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
                }
        );
    }
    
    const resetState = () => {
        setSearchTriggered(false);
        setResultsLoaded(false);
        setJobReportList([]);
        setDisableButton(false);
        nextToken.current = null;
        message.current = "";
    }

       const triggerJobSearch =  async(props) => {
           setSearchTriggered(true);
            let payload = {
                messageType: message.current,
                nextToken: nextToken.current
            };
    
            await JobSearchService.getJobReport(payload).then(
                (response) => {
                    setResultsLoaded(true);
                    setDisableButton(false)
                    if (response.status === 200) { 

                        if (props === 'search button clicked') 
                            setJobReportList([...response.data.data]);
                        else 
                            setJobReportList(jobReportList => [...jobReportList, ...response.data.data])
                            
                        nextToken.current = response.data.nextToken;
                        hasMore.current = nextToken.current === undefined ? false:true

                    }
                    if(response.status === 204){
                        toast.error(NODE_INTG_MESSAGES.NO_RESULTS_FOUND);
                    }
                }).catch(
                    (response) => {
                    setDisableButton(false)
                    toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
                    }
            );
        }

    const observer = useRef(
        new IntersectionObserver(
            (entries) => {
                const first = entries[0];
                if (first.isIntersecting && hasMore.current) {
                    triggerJobSearch('infinite scroll');
                }
            })
    );

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);



    useEffect( () => {
        async function messageTypeLoad() {
            setShowSpinner(true)
            let response = await CommonService.getMessageTypeInformation();
            response.data.messageTypes['All'] = [];
            setMessageTypeList(Object.keys(response.data.messageTypes).sort());
            const msg = Object.keys(response.data.messageTypes).length > 0 ? Object.keys(response.data.messageTypes).sort()[0] : "";
            setMessageType(msg)
            message.current = msg;
            setShowSpinner(false)
        }
        messageTypeLoad();
    }, []);

    return (
        <NikeDesignSystemProvider>
            <div className={style.subTitle}>Job Report</div>
            <div className={style.searchSection}>
                    
                    <span  style={{width:'35%'}}>
                        <Typeahead
                            id="ta-controls"
                                label="Message Type"
                                controlledValue={msgType}
                                noResultsMessage="No Message Type Found"
                                searchableItems={msgTypeList}
                        showListboxWhenEmpty={true}
                        onItemSelect={ (selectedValue) => {
                            setMessageType(selectedValue);
                            message.current = selectedValue;
                        }}
                        onChange={(event) => {
                            resetState();
                        }}
                            />
                        </span>              
                    
            </div>

            <div className={style.searchSection}>
                <span style={{ marginRight:'1%'}}>
                    <IconButton
                        type="Search"
                        iconSize="m"
                        disabled={disableButton}
                        onClick={()=>triggerJobSearch('search button clicked')}
                        theme={{
                            primary: !disableButton ? "#000000" : "#e5e5e5",
                            secondary: !disableButton ? "#ffffff" : "#757575",
                        }}
                        >
                        Search
                        </IconButton>
                
                </span>  
                <span>
                    <IconButton
                            type="CaretLeft"
                            iconSize="m"
                            onClick={()=>props.backButtonHandler(true)}
                                >
                            Back
                    </IconButton>
                </span>    
            </div>
            <div className={style.searchSection}>
                
            </div>  

            <PageSpinner show={spinner} />


            <div style={{justifyContent:'center', paddingLeft:'4%'}}>
                <Box padding="s" maxWidth="95%">
                    {
                        searchTriggered?
                             !resultsLoaded ?
                                <Stack gapXs="m" display="false">
                                    <Skeleton width="100%" height="100px" borderRadius="s" />
                                    <Skeleton width="100%" height="25px" />
                                    <Skeleton width="100%" height="25px" />
                                    <Skeleton width="100%" height="25px" />
                                    <Skeleton width="100%" height="25px" />
                               </Stack>
                            : jobReportList.length !== 0 ?
                                            <div>
                                                <div className="search-section">
                                                    <span className="epic-title-3 table-label">
                                                    Search Results
                                                    </span>
                                                </div>
                                                <div style={{paddingLeft:"1%", paddingRight:"1%"}}>
                                            <span className="epic-title-3 table-label">
                                                <div style={{height: '400px',overflowY:'auto', display:'block'}}>
                                                            <TableContainer
                                                                className="table table-hover"
                                                            >
                                                                <Thead>
                                                                    <Tr>
                                                                        {
                                                                        
                                                                        tableCol.map((headerItem, headerIndex) => (
                                                                            <Th
                                                                            key={headerIndex}
                                                                            className={`thead col-${headerItem.columnCount}`}
                                                                            >
                                                                                {headerItem.Header}
                                                                            </Th>
                                                                        ))}
                                                                        <Th className="col-1 thead">
                                                                            JobDetail
                                                                        </Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {jobReportList.map((dataItem, index) => (
                                                                        <Tr key={`tr-${index}`}>
                                                                            {
                                                                            tableCol.map((head, headIndex) => 
                                                                            (
                                                                                
                                                                                    (jobReportList.length === index + 1) ?
                                                                                        <Td key={`trc-${head.accessor}-${headIndex}`} className={`td-compact-table-cell-padding col-${head.columnCount}`}>
                                                                                            <div ref={setLastElement}>
                                                                                            {       
                                                                                               head.accessor === 'updatedTimeStamp' ? dataItem[head.accessor] + ' UTC': dataItem[head.accessor]
                                                                                            }
                                                                                            </div>
                                                                                        </Td> :
                                                                                        <Td key={`trc-${head.accessor}-${headIndex}`} className={`td-compact-table-cell-padding col-${head.columnCount}`}>
                                                                                            <div>
                                                                                            {  
                                                                                                head.accessor === 'updatedTimeStamp' ? dataItem[head.accessor] + ' UTC': dataItem[head.accessor]
                                                                                            }
                                                                                            </div>
                                                                                        </Td>
                                                                                
                                                                            )   )}
                                                                            <Td className={'td-compact-table-cell-padding col-1'}>
                                                                                <IconButton
                                                                                    type="Download"
                                                                                    iconsize="m"
                                                                                    onClick={()=>triggerDownload(dataItem['messageType'],dataItem['jobId'])}
                                                                                    small
                                                                                />
                                                                            </Td>
                                                                        </Tr>
                                                                    ))}
                                                                </Tbody>
                                                    </TableContainer>
                                                </div>    
                                                    </span>
                                                </div>
                                            </div> : <div />
                            : <div />
                        }
                </Box>
            </div>
    </NikeDesignSystemProvider>    
    );
};

export default JobReport;