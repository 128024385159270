import React, {useEffect, useState} from "react";
import MessageDetails from "./MessageDetails";
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
    NikeDesignSystemProvider
} from "@nike/nike-design-system-components";
import {Button, Modal, PageSpinner} from "@nike/epic-react-ui";
import API_INTERFACE_URI from "../constants/uri.constants";
import config from "../config/config";
import NODE_INTG_MESSAGES from "../constants/app.constants";
import moment from "moment";
import {SearchService} from "../services/service.search";
import {toast} from "react-toastify";
import {RetryService} from "../services/service.retry";
function StatusMessage() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const[showConfirmModal, setShowConfirmModal] = useState(false)
    const[showSpinner, setShowSpinner] = useState(true)

    const initialValue = {
        "messageType":"",
        "businessValue":"",
        "messageId":"_",
        "status":"",
        "retryable":false,
        "retryCount":0,
        "eventTimeStamp":"",
        "errorReason":""
    }

    const[results, setResults] = useState(initialValue);

    const retrySelectedApi = async () => {
        let payload = {
            messageId: results.messageId,
            messageType: results.messageType
        };
        setShowConfirmModal(false);
        setShowSpinner(true);

        await RetryService.retryAction([payload]).then(
            (response) => {
                setShowSpinner(false);
                setShowConfirmModal(false);
                toast.success(NODE_INTG_MESSAGES.MESSAGE_RETRY_SUCCESS, {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch(
            (response) => {
                setShowSpinner(false);
                setShowConfirmModal(false);
                toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
            });
    }

    const buildAndOpenSplunkLogURI = (messageId, timeStamp) => {
        var splunkIndex = config.isProd ? NODE_INTG_MESSAGES.SPLUNK_INDEX_PROD : NODE_INTG_MESSAGES.SPLUNK_INDEX_NON_PROD
        var eventTimeStamp = new Date(moment(timeStamp).subtract(1, 'day').format("yyyy-MM-DD")).getTime()/1000;
        window.open(API_INTERFACE_URI.SPLUNK_LOG_BASE_URI.concat(` index=${splunkIndex}`).concat(` sourcetype="${NODE_INTG_MESSAGES.SPLUNK_SOURCE_TYPE}"`).concat(` messageId="${messageId}"`).concat(` geo="${config.geo}"`).concat(` env="${config.appEnv}"`).concat(`&display.page.search.mode=verbose&dispatch.sample_ratio=1&earliest=${eventTimeStamp}&latest=now`), '_blank', 'noopener,noreferrer')
    }

    const handleSpinnerState = (isSpinnerOpen) => {
        setShowSpinner(isSpinnerOpen);
    }

    useEffect(() => {
        let messagePayload = {
            messageType: searchParams.get('messageType'),
            businessValue: searchParams.get('messageId').trim(),
            messageStatus: searchParams.get('status'),
            endDate: moment(searchParams.get('date')).format('YYYY-MM-DD'),
            startDate: moment(searchParams.get('date')).subtract(1, 'day').format('YYYY-MM-DD'),
            businessKeyFilterCriteria: false
        };
        async function triggerSearch() {
            await SearchService.searchMessages(messagePayload).then(
                (response) => {
                    setResults(response.status === 200 ? response.data.objects[0] : {})
                    if(response.status === 204){
                        toast.error(NODE_INTG_MESSAGES.NO_RESULTS_FOUND);
                    }
                    setShowSpinner(false)
                }).catch(
                (response) => {
                    toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
                    setShowSpinner(false)
                });
        }
        triggerSearch()
    }, [searchParams]);

    return (
        <div id="modal-root" style={{paddingTop:'5%', paddingLeft:'8%', paddingRight:'8%'}}>
            <NikeDesignSystemProvider>
                <Modal
                    onClose={() => setShowConfirmModal(false)}
                    closeButton
                    swoosh
                    show={showConfirmModal}
                    test-id="confirm-model"
                >
                    <Modal.Title>
                        <h3 style={{color:'#000000'}}>Retry Selected Message ?</h3>
                    </Modal.Title>
                    <Modal.Actions>
                        <Button
                            small
                            className="button"
                            onClick={() => setShowConfirmModal(false)}
                            test-id="modal-no-button"
                            theme={{
                                primary: "#000000",
                                secondary: "#ffffff",
                            }}
                        >
                            No
                        </Button>
                        <Button
                            small
                            className="button"
                            test-id="modal-yes-button"
                            onClick={retrySelectedApi}
                            theme={{
                                primary: "#228B22",
                                secondary: "#ffffff",
                            }}
                        >
                            Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
                <div className="details-section" style={{paddingBottom:'1%'}}>
                    <span className="details-field" style={{width:'44%'}}>
                        <h2><b>Message Details</b></h2>
                    </span>
                    <span className="details-field" style={{width:'11%'}}>
                        <Button
                            small="true"
                            className="button"
                            onClick={() => navigate("/")}
                        >
                            Close
                        </Button>
                    </span>
                    <span className="details-field" style={{width:'11%'}}>
                        <Button
                            small="true"
                            className="button"
                            disabled={!results.retryable}
                            onClick={() => setShowConfirmModal(true)}
                        >
                            Retry
                        </Button>
                    </span>
                    <span className="details-field" style={{width:'11%'}}>
                        <Button
                            small="true"
                            className="button"
                            onClick={() => window.open(`${API_INTERFACE_URI.SIGNAL_FX_APM_TRACE}${results.traceId}`, '_blank', 'noopener,noreferrer')}
                        >
                            Trace
                        </Button>
                    </span>
                    <span className="details-field" style={{width:'11%'}}>
                        <Button
                            small="true"
                            className="button"
                            onClick={() => buildAndOpenSplunkLogURI(results.messageId.split("_")[0], results.eventTimeStamp)}
                        >
                            Logs
                        </Button>
                    </span>
            </div>
            <MessageDetails data={results} businessValue={results.messageId.split("_")[0]} fromUI={false} handleSpinnerState={handleSpinnerState} />
            <PageSpinner show={showSpinner} />
            </NikeDesignSystemProvider>
        </div>

    );
}

export default StatusMessage;
