import React from "react";
import {IconButton, Table} from "@nike/epic-react-ui";
import {TextFieldStyled} from "@nike/nike-design-system-components";
import NODE_INTG_MESSAGES from "../constants/app.constants";
import MultipleDestinationsRetry from "./MultipleDestinationsRetry";
import {CommonService} from "../services/service.common";
import {toast} from "react-toastify";

function MessageDetails(props) {

    const nodeTableColumns = [
        {
            Header: 'Destination',
            accessor: 'destinationId'
        },
        {
            Header: 'Status',
            accessor: 'status'
        },
        {
            Header: 'Failure Reason',
            accessor: 'failureReason',
            disableGlobalFilter: true,
            disableSortBy: true,
        }
    ]


    const renderActionButtons = (selectedRowIds) => {
        let selectedDestinationIds = []
        for(let count = 0; count < selectedRowIds.selectedFlatRows.length; count ++){
            selectedDestinationIds.push(selectedRowIds.selectedFlatRows[count].original.destinationId)
        }
        return <MultipleDestinationsRetry selectedDestinations = {selectedDestinationIds} messageId = {props.data.messageId} messageType = {props.data.messageType}/>
    }

    const downloadFile = async (messageS3Location, fileDownloadName) => {
        if (props.fromUI) {
            props.handleModalState(false);
        }
        props.handleSpinnerState(true);
        var filename = messageS3Location.split("/").pop();
        let presignedUrl;
        let payload = {
            messageS3Location: messageS3Location
        }
        await CommonService.preSignedUrlMethod(payload).then(
            (response) => {
                presignedUrl = response.data.preSignedUrl;
            }).catch(
            (response) => {
                props.handleSpinnerState(false)
                toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_FILE_DOWNLOAD);
            });
        if(presignedUrl !== undefined){
            await CommonService.downloadMessageWithPreSignedURL(presignedUrl).then(
                (response) => {
                    let element = document.createElement('a');
                    var fileExtention = filename.split(".").pop();
                    if(fileExtention === 'json'){
                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(response.data,null,2)));
                    }else if(fileExtention === 'xml'){
                        var xmlparser = new DOMParser();
                        var xmlDoc = xmlparser.parseFromString(response.data, "text/xml");
                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(new XMLSerializer().serializeToString(xmlDoc.documentElement)));

                    }else {
                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.data));
                    }
                    element.setAttribute('download', fileDownloadName + "." + fileExtention);
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                    props.handleSpinnerState(false);
                }).catch(
                (response) => {
                    props.handleSpinnerState(false);
                    toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_FILE_DOWNLOAD);
                })
        }
        props.handleSpinnerState(false);
        if (props.fromUI) {
            props.handleModalState(true);
        }
    }

    return (
        <div>
            <div className="details-section">
            <span className="details-field" style={{width:'22%'}}>
                <TextFieldStyled
                    className="no-pointers"
                    value={props.data.messageType}
                    type="text"
                    readOnly={false}
                    placeholder="Message Type"
                    name="messageType"
                    id="messageType"
                    label="Message Type"
                />
            </span>
                <span className="details-field" style={{width:'22%'}}>
                <TextFieldStyled
                    className="no-pointers"
                    value={props.businessValue}
                    type="text"
                    readOnly={false}
                    placeholder="Search Criteria"
                    name="businessKey"
                    id="businessKey"
                    label="Search Criteria"
                />
            </span>
                <span className="details-field" style={{width:'44%'}}>
                <TextFieldStyled
                    className="no-pointers"
                    value={props.data.messageId}
                    type="text"
                    readOnly={false}
                    placeholder="Message Id"
                    name="messageId"
                    id="messageId"
                    label="Message Id"
                />
            </span>
            </div>
            <div className="details-section">
            <span className="details-field" style={{width:'22%'}}>
                <TextFieldStyled
                    className="no-pointers"
                    value={props.data.status}
                    type="text"
                    placeholder="Status"
                    name="status"
                    id="status"
                    label="Status"
                />
            </span>
                <span className="details-field" style={{width:'22%'}}>
                <TextFieldStyled
                    className="no-pointers"
                    value={props.data.retryable.toString()}
                    type="text"
                    readOnly={false}
                    placeholder="Retryable"
                    name="timestamp"
                    id="retryable"
                    label="Retryable"
                />
            </span>
                <span className="details-field" style={{width:'22%'}}>
                <TextFieldStyled
                    className="no-pointers"
                    value={props.data.retryCount.toString()}
                    type="text"
                    readOnly={false}
                    placeholder="Retry Count"
                    name="retryCount"
                    id="retryCount"
                    label="Retry Count"
                />
            </span>
                <span className="details-field" style={{width:'22%'}}>
                <TextFieldStyled
                    className="no-pointers"
                    value={props.data.eventTimeStamp}
                    type="text"
                    placeholder="Event Timestamp"
                    name="status"
                    id="status"
                    label="Event Timestamp"
                />
            </span>
            </div>
            <div className="details-section">
            <span className="details-field" style={{width:'88%'}}>
                <TextFieldStyled
                    className="no-pointers"
                    value={props.data.errorReason}
                    type="text"
                    readOnly={false}
                    placeholder="Status Reason"
                    name="messageFailedReason"
                    id="messageFailedReason"
                    label="Status Reason"
                />
            </span>
            </div>
            <div className="details-section padding">
                <Table
                    BulkSelectRowAside={(selectedRowIds) => renderActionButtons(selectedRowIds)}
                    columns={nodeTableColumns}
                    data={props.data.destinations}
                    isCompact
                    visibleRows={4}
                    withSelectableRows
                />
            </div>
            <div className="details-section">
            <span className="details-field padding">
                <IconButton
                    medium="true"
                    iconSize="m"
                    className="button"
                    disabled={!("sourceMessageLocation" in props.data)}
                    onClick={() => downloadFile(props.data.sourceMessageLocation, props.data.messageId+"_source")}
                    type="Download"
                    style={{width:'200px'}}
                >
                    Source
                </IconButton>
            </span>
                <span className="details-field padding">
                <IconButton
                    medium="true"
                    className="button"
                    iconSize="m"
                    disabled={!("transformedMessageLocation" in props.data)}
                    onClick={() => downloadFile(props.data.transformedMessageLocation, props.data.messageId+"_transformed")}
                    type="Download"
                    style={{width:'200px'}}
                >
                    Transformed
                </IconButton>
            </span>
                <span className="details-field padding">
                <IconButton
                    medium="true"
                    iconSize="m"
                    className="button"
                    onClick={() => downloadFile(props.data.preprocessorMessageLocation, props.data.messageId+"_preprocessor")}
                    disabled={!("preprocessorMessageLocation" in props.data)}
                    type="Download"
                    style={{width:'200px'}}
                >
                    Pre-Processor
                </IconButton>
            </span>
            </div>
        </div>
    );
}

export default MessageDetails;
