import Card from "../component/Card";
import style  from '../containers/styles/TableCard.module.css';

const TableCard = (props) => {

    return (
            <div className="table">
                    <div className={style.tableHeading}>
                            SELECT CARD TO RETRY MESSAGE OR VIEW JOB REPORT
                    </div>
                     <div className={`row ${style.tableBody}`}>
                    {   props.retryMap.map((tab) => (
                        <Card name={tab.name} description={tab.description} icon={tab.icon} selectedOption={props.selectedOption} key={tab.name} color={tab.color}></Card>
                    ))}
                    </div>
            </div>
    )
}

export default TableCard;