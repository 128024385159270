import {
    DatePickerStyled,
    NikeDesignSystemProvider,
    TextFieldStyled,
    Typeahead,
    Skeleton, Box, Stack
} from "@nike/nike-design-system-components";
import { IconButton, TableContainer, Tr, Checkbox, Td, Tbody, PageSpinner, Tooltip, Icon } from "@nike/epic-react-ui";
import React, { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import { CommonService } from "../services/service.common"
import { SearchService } from "../services/service.search"
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "./styles/search.css";
import "@nike/epic-react-ui/dist/styles/main.css";
import SingleRow from "./SingleRow";
import MultipleRows from "./MultipleRows";
import NODE_INTG_MESSAGES from "../constants/app.constants";

const INITIAL_NUMBER_OF_DAYS_FOR_SEARCH = 7;
const MAX_ALLOWED_DAYS_FOR_SEARCH = 90;
const MAX_PREVIOUS_DAYS_ALLOWED_FOR_SEARCH = 99;
const filterCriteriaList = ["Business Key", "Message ID", "Trace ID"];

const Search = (props) => {
    const [searchValue, setSearchValue] = useState("");
    const [searchIdentifier, setSearchIdentifier] = useState(filterCriteriaList[0]);
    const [searchValueError, setSearchValueError] = useState(true);

    const [businessKeys, setBusinessKeys] = useState([]);
    const [messageType, setMessageType] = useState("");

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [status, setStatus] = useState("all");
    const [node, setNode] = useState("");
    const [nodeValueError, setNodeValueError] = useState(false);
    const [messageTypeList, setMessageTypeList] = useState([]);
    const [messageTypeMap, setMessageTypeMap] = useState({});
    const [searchResults, setSearchResults] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const [searchTriggered, setSearchTriggered] = useState(false);
    const [resultsLoaded, setResultsLoaded] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [disableMessage, setDisableMessage] = useState(NODE_INTG_MESSAGES.INVALID_INPUT);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const [showToolTip, setShowToolTip] = useState(true);
    const [sortTimestampAscending, setSortTimestampAscending] = useState(false);
    const [sortRetryCountAscending, setSortRetryCountAscending] = useState(true);

    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1)
    const [recordCount, setRecordCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [queryId, setQueryId] = useState("")
    const [nextToken, setNextToken] = useState("")


    const observer = useRef();
    const lastStatusRef = useCallback(statusMessage => {
        console.log("Ref is called")
        if (loading) {
            console.log("Loading if is called")
            return
        }
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (statusMessage) observer.current.observe(statusMessage)
    }, [loading, hasMore])

    const resetState = () => {
        setDisableButton(false);
        setSearchTriggered(false);
        setResultsLoaded(false);
        setSelectAllCheckBox(false);
        setNodeValueError(false);
        setHasErrors(false);
        setSelectedRows([]);
        setSearchResults([]);
        setHasMore(false);
        setPageNumber(1);
        setQueryId('');
        setNextToken('');
    }

    const resultsColumns = [
        {
            Header: 'MessageId',
            accessor: 'messageId',
            columnCount: 2,
            sortEnabled: false
        },
        {
            Header: 'Message Timestamp',
            accessor: 'eventTimeStamp',
            columnCount: 2,
            sortEnabled: true
        },
        {
            Header: 'Message Type',
            accessor: 'messageType',
            columnCount: 2,
            sortEnabled: false
        },
        {
            Header: 'Status',
            accessor: 'status',
            columnCount: 1,
            sortEnabled: false
        },
        {
            Header: 'Retry count',
            accessor: 'retryCount',
            columnCount: 1,
            sortEnabled: true
        },
        {
            Header: 'Retryable',
            accessor: 'retryable',
            columnCount: 1,
            sortEnabled: false
        }
    ]

    useEffect(() => {
        setFromDate(moment().subtract(INITIAL_NUMBER_OF_DAYS_FOR_SEARCH, 'day').format("yyyy-MM-DD"))
        setToDate(moment().format("yyyy-MM-DD"))
    }, []);

    useEffect(() => {
        if (pageNumber > 1) {
            loadItems();
        }
    }, [pageNumber])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function messageTypeLoad() {
            setShowSpinner(true)
            let response = await CommonService.getMessageTypeInformation();
            response.data.messageTypes['All'] = [];
            setMessageTypeMap(response.data.messageTypes);
            setMessageTypeList(Object.keys(response.data.messageTypes).sort());
            setMessageType(Object.keys(response.data.messageTypes).length > 0 ? Object.keys(response.data.messageTypes).sort()[0] : "");
            setBusinessKeys(response.data.messageTypes[Object.keys(response.data.messageTypes).sort()[0]].join(' || '));
            setShowSpinner(false)
        }
        messageTypeLoad();
    }, []);

    useEffect(() => {
        validateSearchCriteria(toDate, fromDate, searchIdentifier, searchValue, status, messageType);
    }, [toDate, fromDate, searchIdentifier, searchValue, status, messageType, node]);

    const validateSearchCriteria = (toDateValue, fromDateValue, searchIdentifierValue, tempSearchValue,
        statusValue, messageTypeValue) => {
        let isInValid = false;
        if ((fromDateValue === '' && toDateValue !== '') || (toDateValue < fromDateValue) || parseInt((new Date(toDateValue) - new Date(fromDateValue)) / (1000 * 60 * 60 * 24), 10) > MAX_ALLOWED_DAYS_FOR_SEARCH) {
            setHasErrors(true);
            setDisableButton(true);
            isInValid = true
        } else {
            setHasErrors(false);
        }
        if (searchIdentifierValue === '' || tempSearchValue === '' || statusValue === ''
            || messageTypeValue === '') {
            isInValid = true
        }
        setDisableButton(isInValid);
    }

    const sortResults = (key) => {
        let sortedSearchResults = searchResults.sort(sortByProperty(key));
        if ((key === 'eventTimeStamp' && sortTimestampAscending) || (key === 'retryCount' && sortRetryCountAscending)) {
            sortedSearchResults = searchResults.sort(sortByProperty("eventTimeStamp"));
            if (sortTimestampAscending) {
                sortedSearchResults = sortedSearchResults.reverse();
            }
            setSearchResults(sortedSearchResults);
        }
    }

    const sortByProperty = (keyName) => {
        return function (a, b) {
            if (a[keyName] > b[keyName])
                return 1;
            else if (a[keyName] < b[keyName])
                return -1;
            return 0;
        }
    }

    const triggerSearch = async () => {
        setPageNumber(1);
        setShowSpinner(true);
        setLoading(true);
        setDisableButton(true)
        setSearchTriggered(true)
        setResultsLoaded(false)
        setDisableMessage(NODE_INTG_MESSAGES.REQUEST_IN_PROGRESS)
        toast.success(NODE_INTG_MESSAGES.MESSAGE_SEARCH_TRIGGER_SUCCESS);
        let searchIdentifierVal = '';
        if (searchIdentifier === 'Business Key') {
            searchIdentifierVal = 'businessKey';
        } else if (searchIdentifier === 'Message ID') {
            searchIdentifierVal = 'messageId';
        } else if (searchIdentifier === 'Trace ID') {
            searchIdentifierVal = 'traceId';
        }
        let payload = {
            messageType: messageType,
            searchValue: searchValue.trim(),
            messageNode: node,
            messageStatus: status,
            endDate: toDate,
            startDate: fromDate,
            searchIdentifier: searchIdentifierVal
        };

        let searchHeaders = {
            queryId: '',
            athenaNextToken: ''
        }

        await SearchService.searchMessages(payload, searchHeaders).then(
            (response) => {
                setDisableButton(false)
                setResultsLoaded(true)
                setSearchResults(response.status === 200 ? response.data.objects : [])
                setRecordCount(response.status === 200 ? response.data.objects.length : 0)
                setTotalCount(response.status === 200 ? response.headers['totalsearchcount'] : 0)
                if (response.headers['next-token'] !== undefined) {
                    setQueryId(response.headers['queryid']);
                    setNextToken(response.headers['next-token']);
                    setHasMore(true);
                }
                if (response.status === 204) {
                    toast.error(NODE_INTG_MESSAGES.NO_RESULTS_FOUND);
                }
                setShowSpinner(false);
            }).catch(
                (response) => {
                    setDisableButton(false)
                    setResultsLoaded(true)
                    setShowSpinner(false);
                    toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
                });
        setLoading(false);
    }

    const loadItems = async () => {
        setShowSpinner(true)
        setLoading(true)
        toast.success(NODE_INTG_MESSAGES.MESSAGE_SEARCH_TRIGGER_SUCCESS);
        let searchIdentifierVal = '';
        if (searchIdentifier === 'Business Key') {
            searchIdentifierVal = 'businessKey';
        } else if (searchIdentifier === 'Message ID') {
            searchIdentifierVal = 'messageId';
        } else if (searchIdentifier === 'Trace ID') {
            searchIdentifierVal = 'traceId';
        }
        let payload = {
            messageType: messageType,
            searchValue: searchValue.trim(),
            messageNode: node,
            messageStatus: status,
            endDate: toDate,
            startDate: fromDate,
            searchIdentifier: searchIdentifierVal
        };

        let searchHeaders = {
            queryId: queryId,
            athenaNextToken: nextToken
        }

        await SearchService.searchMessages(payload, searchHeaders).then(
            (response) => {
                setRecordCount(response.status === 200 ? recordCount + response.data.objects.length : recordCount);
                setSearchResults(response.status === 200 ? [...searchResults, ...response.data.objects] : searchResults)
                if (response.status === 204) {
                    toast.error(NODE_INTG_MESSAGES.NO_RESULTS_FOUND);
                }
                setShowSpinner(false)
                if (response.headers['next-token'] === null || response.headers['next-token'] === undefined) {
                    setHasMore(false);
                    setQueryId('');
                    setNextToken('');
                } else {
                    setHasMore(true);
                    setQueryId(response.headers['queryid'])
                    setNextToken(response.headers['next-token'])
                }
            }).catch(
                (response) => {
                    setShowSpinner(false)
                    toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
                });
        setLoading(false);
    }

    const validateBusinessValue = (value) => {
        setSearchValue(value);
        if (value === '') {
            setSearchValueError(true)
        } else {
            setSearchValueError(false)
        }
    }

    const handleState = (messageIds) => {
        let updatedArray = searchResults.filter((result) => !messageIds.includes(result.messageId));
        setSearchResults(updatedArray);
    }

    const handleSelectedMessageIdsState = () => {
        setSelectedRows([]);
        setSelectAllCheckBox(false);
    }

    const downloadFile = async (messageS3Location, fileDownloadName) => {
        setShowSpinner(true);
        var filename = messageS3Location.split("/").pop();
        let presignedUrl;
        let payload = {
            messageS3Location: messageS3Location
        }
        await CommonService.preSignedUrlMethod(payload).then(
            (response) => {
                presignedUrl = response.data.preSignedUrl;
            }).catch(
                (response) => {
                    setShowSpinner(false)
                    toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_FILE_DOWNLOAD);
                });
        if (presignedUrl !== undefined) {
            await CommonService.downloadMessageWithPreSignedURL(presignedUrl).then(
                (response) => {
                    let element = document.createElement('a');
                    var fileExtention = filename.split(".").pop();
                    if (fileExtention === 'json') {
                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(response.data, null, 2)));
                    } else if (fileExtention === 'xml') {
                        var xmlparser = new DOMParser();
                        var xmlDoc = xmlparser.parseFromString(response.data, "text/xml");
                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(new XMLSerializer().serializeToString(xmlDoc.documentElement)));

                    } else {
                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.data));
                    }
                    element.setAttribute('download', fileDownloadName + "." + fileExtention);
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                    setShowSpinner(false);
                }).catch(
                    (response) => {
                        setShowSpinner(false);
                        toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_FILE_DOWNLOAD);
                    })
        }
        setShowSpinner(false);
    }

    const handleCheckBox = (dataItem) => {
        let checkboxState = dataItem.selected;
        let index = searchResults.indexOf(dataItem);
        let tempSearchResults = searchResults.filter((result) => result.messageId !== dataItem.messageId);
        dataItem.selected = !checkboxState;
        tempSearchResults.splice(index, 0, dataItem);
        setSearchResults(tempSearchResults);

        let tempSelectedIds = selectedRows;
        let selectedRowIndex = tempSelectedIds.indexOf(dataItem.messageId);
        selectedRowIndex === -1 ? tempSelectedIds.push(dataItem.messageId) : tempSelectedIds.splice(selectedRowIndex, 1);
        setSelectedRows(tempSelectedIds);
        setSelectAllCheckBox(tempSelectedIds.length > 0)
    }

    const handleSelectAllCheckBox = () => {
        let tempResults = searchResults;
        let selectedRows = [];
        setSearchResults([]);
        tempResults.map(dataItem => {
            if (dataItem.retryable) {
                dataItem.selected = !selectAllCheckBox;
                if (!selectAllCheckBox) {
                    selectedRows.push(dataItem.messageId);
                }
            }
            return 0;
        })
        setSelectedRows(selectedRows);
        setSelectAllCheckBox(!selectAllCheckBox);
        setSearchResults(tempResults);
    }

    return (
        <div id="modal-root" className="bg-height">
            <NikeDesignSystemProvider>
                <div className="search-section"> 
                    <span className="search-field-span" style={{ width: '25%' }}>
                        <Typeahead onInputChange={() => {
                            setShowToolTip(false);
                            setMessageType('');
                        }}
                            id="message-type-list"
                            label="Message Type*"
                            selectedValue={messageType}
                            controlledValue={messageType}
                            noResultsMessage="Message Type List not found"
                            searchableItems={messageTypeList}
                            showListboxWhenEmpty={true}
                            onItemSelect={(selectedValue) => {
                                resetState();
                                setBusinessKeys(messageTypeMap[selectedValue].join(' || '));
                                setMessageType(selectedValue);
                                setShowToolTip(true);
                            }}
                        />
                        {showToolTip && <div className="tooltip">{messageType}</div>}
                    </span>
                    <span className="search-field-span" style={{ width: '8%' }}>

                        <TextFieldStyled
                            value={node}
                            type="text"
                            error={nodeValueError}
                            name="node"
                            test-id="node-test-id"
                            id="node"
                            label="Node"
                            onChange={(event) => {
                                resetState();
                                setNode(event.target.value)
                            }}
                        />
                    </span>
                    <span className="search-field-span" style={{ width: '9%' }}>
                        <Typeahead onInputChange={() => setStatus('')}
                            id="status"
                            label="Status*"
                            controlledValue={status}
                            noResultsMessage="Message Type not found"
                            searchableItems={["all", "success", "failed", "filtered"]}
                            showListboxWhenEmpty={true}
                            onItemSelect={(selectedValue) => {
                                if (selectedValue !== status) {
                                    resetState();
                                    setStatus(selectedValue)
                                }
                            }}
                        />
                    </span>
                    <span className="search-field-span" style={{ width: '13%' }}>
                        <DatePickerStyled
                            name="Search window from date"
                            id="search-from-date"
                            label="From"
                            placeholder="From"
                            error={hasErrors}
                            errorMessage={"Date Range should be " + MAX_ALLOWED_DAYS_FOR_SEARCH + " days"}
                            autoComplete="off"
                            value={fromDate}
                            shouldUseFallback={false}
                            iconTitle="Calendar Icon"
                            datepickerfallbackprops={{ monthPlaceholder: "mm", dayPlaceholder: "dd", yearPlaceholder: "yyyy", a11yMonthLabel: "Month", a11yDayLabel: "Day", a11yYearLabel: "Year", dateSeparator: "/" }}
                            onChange={(event) => {
                                resetState();
                                setFromDate(event.target.value)
                            }}
                        />
                    </span>
                    <span className="search-field-span" style={{ width: '13%' }}>
                        <DatePickerStyled
                            name="Search window to date"
                            id="search-to-date"
                            label="To"
                            placeholder="To"
                            error={hasErrors}
                            errorMessage={"Date Range should be " + MAX_ALLOWED_DAYS_FOR_SEARCH + " days"}
                            autoComplete="off"
                            shouldUseFallback={false}
                            iconTitle="Calendar Icon"
                            datepickerfallbackprops={{ monthPlaceholder: "mm", dayPlaceholder: "dd", yearPlaceholder: "yyyy", a11yMonthLabel: "Month", a11yDayLabel: "Day", a11yYearLabel: "Year", dateSeparator: "/" }}
                            ariaLive="polite"
                            value={toDate}
                            onChange={(event) => {
                                resetState();
                                setToDate(event.target.value)
                            }}
                        />
                    </span>
                    <span className="search-field-span" style={{ width: '15%' }}>
                        <Typeahead onInputChange={() => setSearchIdentifier('')}
                            id="search-criteria-id"
                            label="Filter Criteria*"
                            controlledValue={searchIdentifier}
                            noResultsMessage="Filter criteria not found"
                            searchableItems={filterCriteriaList}
                            showListboxWhenEmpty={true}
                            onItemSelect={(selectedValue) => {
                                if (selectedValue !== searchIdentifier) {
                                    resetState();
                                    setSearchIdentifier(selectedValue)
                                }
                            }}
                        />
                    </span>
                    <span className="search-field-span" style={{ width: '25%' }}>
                        <div style={{ display: "flex" }}>
                            <span style={{ width: '100%' }}>
                                <TextFieldStyled
                                    value={searchValue}
                                    type="text"
                                    error={searchValueError}
                                    requiredIndicator="*"
                                    placeholder={searchIdentifier}
                                    name="businessValueField"
                                    test-id="business-test-id"
                                    id="searchValue"
                                    label={searchIdentifier}
                                    onChange={(event) => {
                                        resetState();
                                        validateBusinessValue(event.target.value)
                                    }}
                                />
                            </span>

                            <span>
                                <Tooltip message={businessKeys} position='left' size="medium"><Icon size="s" type="Info" /></Tooltip>
                            </span>
                        </div>
                    </span>
                </div>
                <div style={{ paddingTop: '1%', paddingLeft: '45.5%' }}>
                    <span className="button-section-padding">
                        <IconButton
                            type="Search"
                            iconSize="m"
                            disabled={disableButton}
                            onClick={triggerSearch}
                            disabledTip={{
                                message: disableMessage,
                                position: "right",
                                size: "medium",
                            }}
                            theme={{
                                primary: !disableButton ? "#000000" : "#e5e5e5",
                                secondary: !disableButton ? "#ffffff" : "#757575",
                            }}
                        >
                            Search
                        </IconButton>

                    </span>
                </div>
                <PageSpinner show={showSpinner} />
                <div style={{ justifyContent: 'center', paddingTop: "2%" }}>
                    <Box >
                        {searchTriggered ?
                            !resultsLoaded ?
                                <Stack gapXs="m" display="false">
                                    <Skeleton width="100%" height="200px" borderRadius="s" />
                                    <Skeleton width="50%" height="20px" />
                                    <Skeleton width="50%" height="20px" />
                                    <Skeleton width="50%" height="20px" />
                                </Stack> :
                                searchResults.length !== 0 ?
                                    <div>
                                        <div className="search-section">
                                            <span className="epic-title-3 table-label">
                                                Search Results
                                            </span>
                                        </div>
                                        <div style={{ paddingTop: "2%" }}>
                                            <span className="epic-title-3 table-label">
                                                <TableContainer
                                                    className="table table-hover table-striped" >
                                                    <Tbody style={{ height: '400px', overflowY: "auto", display: "block" }}>
                                                        <Tr>
                                                            <Td className="col-1">
                                                                <Checkbox
                                                                    id="checkbox-all"
                                                                    checked={selectAllCheckBox}
                                                                    onChange={handleSelectAllCheckBox}
                                                                />
                                                            </Td>
                                                            {resultsColumns.map((headerItem, headerIndex) => (
                                                                (headerItem.sortEnabled === false) ?
                                                                    <Td
                                                                        key={headerIndex}
                                                                        className={`col-${headerItem.columnCount}`}
                                                                        style={{ fontWeight: 'bolder', color: 'black' }}
                                                                    >
                                                                        {headerItem.Header}
                                                                    </Td>
                                                                    :
                                                                    <Td
                                                                        key={headerIndex}
                                                                        className={`col-${headerItem.columnCount}`}
                                                                        style={{ fontWeight: 'bolder', color: 'black' }}
                                                                    >
                                                                        <div style={{ display: "flex" }}>
                                                                            <span>
                                                                                {headerItem.Header}
                                                                            </span>
                                                                            <span>
                                                                                <IconButton iconSize="m" type={headerItem.accessor === "eventTimeStamp" ?
                                                                                    sortTimestampAscending ? "SortAscending" : "SortDescending" :
                                                                                    sortRetryCountAscending ? "SortAscending" : "SortDescending"
                                                                                }
                                                                                    bare={true}
                                                                                    extraSmall={true}
                                                                                    onClick={() => {
                                                                                        sortResults(headerItem.accessor);
                                                                                        headerItem.accessor === "eventTimeStamp" ?
                                                                                            setSortTimestampAscending(!sortTimestampAscending) :
                                                                                            setSortRetryCountAscending(!sortRetryCountAscending)
                                                                                    }
                                                                                    } />
                                                                            </span>
                                                                        </div>
                                                                    </Td>
                                                            ))}
                                                            <Td className="col-1" style={{ fontWeight: 'bolder', color: 'black' }}>
                                                                Source
                                                            </Td>
                                                            <Td className="col-1" style={{ fontWeight: 'bolder', color: 'black' }}>
                                                                Transform
                                                            </Td>
                                                        </Tr>
                                                        {searchResults.map((dataItem, index) => (
                                                            <Tr key={`tr-${index}`}>
                                                                <Td className="col-1">
                                                                    <Checkbox
                                                                        id={`checkbox-${index}`}
                                                                        disabled={!dataItem.retryable}
                                                                        checked={dataItem.selected}
                                                                        onChange={() => { handleCheckBox(dataItem) }}
                                                                    />
                                                                </Td>
                                                                <Td className="col-2">
                                                                    <SingleRow data={dataItem} startDate={fromDate} endDate={toDate} handleState={handleState} businessValue={searchValue} />
                                                                </Td>
                                                                {(searchResults.length === index + 1) ?
                                                                    <Td className="col-2">
                                                                        <div ref={lastStatusRef}>
                                                                            {moment(dataItem["eventTimeStamp"].toString()).format("yyyy-MM-DD HH:mm:ss") + " UTC"}
                                                                        </div>
                                                                    </Td> :
                                                                    <Td className="col-2">
                                                                        <div>
                                                                            {moment(dataItem["eventTimeStamp"].toString()).format("yyyy-MM-DD HH:mm:ss") + " UTC"}
                                                                        </div>
                                                                    </Td>
                                                                }
                                                                {resultsColumns.map((head, headIndex) => (
                                                                    (headIndex > 1) &&
                                                                    <Td
                                                                        key={`trc-${head.accessor}-${headIndex}`}
                                                                        className={`td-compact-table-cell-padding col-${head.columnCount}`}
                                                                    >
                                                                        <div style={{ display: 'flex'}}>
                                                                        {dataItem[head.accessor].toString()}
                                                                        </div>
                                                                    </Td>
                                                                ))}
                                                                <Td className={'td-compact-table-cell-padding col-1'}>
                                                                    <IconButton
                                                                        type="Download"
                                                                        iconsize="l"
                                                                        small
                                                                        disabled={!("sourceMessageLocation" in dataItem)}
                                                                        onClick={() => downloadFile(dataItem.sourceMessageLocation, dataItem.messageId + "_source")}
                                                                    />
                                                                </Td>
                                                                <Td className={'td-compact-table-cell-padding col-1'}>
                                                                    <IconButton
                                                                        type="Download"
                                                                        iconsize="m"
                                                                        small
                                                                        disabled={!("transformedMessageLocation" in dataItem)}
                                                                        onClick={() => downloadFile(dataItem.transformedMessageLocation, dataItem.messageId + "_transformed")}
                                                                    />
                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </TableContainer>
                                            </span>
                                            <span style={{ display: 'flex', justifyContent: 'end', fontWeight: 'bold', fontStyle: 'italic' }}>
                                                {recordCount} of {totalCount} results loaded
                                            </span>
                                            <span className="search-section" style={ {paddingBottom : '3%'}}>
                                                <MultipleRows selectedMessageIds={selectedRows} messageType={messageType} startDate={fromDate} endDate={toDate}  handleState={handleState} handleSelectedMessageIdsState={handleSelectedMessageIdsState}></MultipleRows>
                                            </span>
                                        </div>
                                    </div> :
                                    <div /> :
                            <div />
                        }
                    </Box>
                </div>
            </NikeDesignSystemProvider>
        </div>
    );
};
export default Search
