
import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service.base";
import { globalHeaders } from "../utilities/http";


function downloadJobDetail(queryParams) {
    let url = API_INTERFACE_URI.JOB_DOWNLOAD_PATH;
    if (queryParams !== undefined) url = url + "?" + queryParams;
    return baseService.get(url, globalHeaders);
}

function getJobReport(payload) {
    return baseService.post(
        API_INTERFACE_URI.JOB_SEARCH_PATH,
        globalHeaders,
        payload
    );
}

export const JobSearchService = {
    getJobReport,
    downloadJobDetail
}
