import {
    DatePickerStyled,
    NikeDesignSystemProvider,
    TextFieldStyled,
    Typeahead,
    DialogStyled
} from "@nike/nike-design-system-components";
import {IconButton, Thead, Tr, Th, TableContainer, Td, Tbody, PageSpinner, Tooltip, Icon, Button, Checkbox} from "@nike/epic-react-ui";
import React, { useState, useEffect } from "react";
import moment from "moment";
import {CommonService} from "../services/service.common"
import {SearchService} from "../services/service.search"
import {RetryService} from "../services/service.retry"
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "./styles/search.css";
import styling from './styles/SuccessMessageRetry.module.css';
import "@nike/epic-react-ui/dist/styles/main.css";
import NODE_INTG_MESSAGES from "../constants/app.constants";
import "./styles/MultipleMessageRetry.css";

const  INITIAL_NUMBER_OF_DAYS_FOR_SEARCH = 7;
const  MAX_ALLOWED_DAYS_FOR_SEARCH = 90;
const MultipleMessageRetry = (props) => {
    
    const [messageTypeBussinessKeyMap, setMessageTypeBussinessKeyMap] = useState();
    const [selectedMessageTypesList, setSelectedMessageTypesList] = useState([]);
    const [showSpinner, setShowSpinner] = useState(true);
    const [messageTypeList, setMessageTypeList] = useState([]);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [disableClose, setDisableClose] = useState(true);
    const [disableAdd, setdisableAdd] = useState(false);
    const [disablePreview, setDisablePreview] = useState(true);
    const [selectAllCheckBox, setSelectAllCheckBox] = useState(true);
    const [selectedMessageMap, setSelectedMessageMap] = useState(new Map());
    const [showToolTip, setShowToolTip] = useState(true);

 
    const failedMessageDetailsColumns = [
        {
            Header: 'Message ID',
            accessor: 'displayMessageId',
            columnCount: 2
        },
        {
            Header: 'Message Type',
            accessor: 'messageType',
            columnCount: 2
        },
        {
            Header: 'Message Status',
            accessor: 'messageStatus',
            columnCount: 1
        },
        {
            Header: 'Business Key',
            accessor: 'selectedBusinessValue',
            columnCount: 3
        },
        {
            Header: 'Failure Reason',
            accessor: 'failedReason',
            columnCount: 2
        },
        {
            Header: 'Retry Count',
            accessor: 'retryCount',
            columnCount: 2
        }
    ]

    useEffect( () => {
        async function messageTypeLoad() {
            setShowSpinner(true)
            let response = await CommonService.getMessageTypeInformation();
            response.data.messageTypes['All'] = [];
            setMessageTypeBussinessKeyMap(response.data.messageTypes);
            setMessageTypeList(Object.keys(response.data.messageTypes).sort());
            let selectedMessage = Object.keys(response.data.messageTypes).length >0 ? Object.keys(response.data.messageTypes).sort()[0] : "";
            setSelectedMessageTypesList([{messageType: selectedMessage, messageStatus: "all", messageNode: "", hasBusinessValueError:true, toDate: moment().format("yyyy-MM-DD"), fromDate:moment().subtract(7, 'day').format("yyyy-MM-DD"), businessValueList: [], businessKeyInfo: response.data.messageTypes[selectedMessage].join(' || ')}]);
            setShowSpinner(false)
        }
        messageTypeLoad();
    }, []);

    useEffect(() => {
        selectedMessageTypesList.length === 1 ? setDisableClose(true) : setDisableClose(false);
        selectedMessageTypesList.length === 5 ? setdisableAdd(true) : setdisableAdd(false);
    }, [selectedMessageTypesList])

    const validateDate = (messageType) => {
        if((messageType.fromDate === '' && messageType.toDate !== '') ||
            (messageType.toDate < messageType.fromDate) || parseInt((new Date(messageType.toDate) - new Date(messageType.fromDate)) / (1000 * 60 * 60 * 24), 10) > MAX_ALLOWED_DAYS_FOR_SEARCH ){
            messageType.hasDateError = true;
            messageType.dateErrorMessage = "Date Range should be "+MAX_ALLOWED_DAYS_FOR_SEARCH+" days"
        }else {
            messageType.hasDateError = false;
        }
        setSelectedMessageTypesList([...selectedMessageTypesList]);
    }

    const validateBusinessValue = (messageType) => {
        if(messageType.businessValueList.length === 0 || messageType.businessValueList.length > 50){
            messageType.hasBusinessValueError = true;
        }else {
            messageType.hasBusinessValueError = false;
        }
        setSelectedMessageTypesList([...selectedMessageTypesList]);
    }

    const validateDisablePreview = () => {
        for(let i = 0; i < selectedMessageTypesList.length; i++) {
            if (selectedMessageTypesList[i].hasDateError || selectedMessageTypesList[i].hasBusinessValueError
                || selectedMessageTypesList[i].messageType.length == 0 || selectedMessageTypesList[i].messageStatus.length == 0 ) {
                setDisablePreview(true);
                return
            }
        }
        setDisablePreview(false);
    }

    const refreshFormData = (refreshMessageType, index, retryMessageType) => {
        retryMessageType.messageType = refreshMessageType;
        retryMessageType.businessKeyInfo = messageTypeBussinessKeyMap[refreshMessageType].join(' || ');
    }

    const addRow = () => {
        let newMessageType = messageTypeList[0];
        setSelectedMessageTypesList([...selectedMessageTypesList, {messageType: newMessageType, messageNode:'newMessageNode', hasBusinessValueError:true, toDate: moment().format("yyyy-MM-DD"), fromDate:moment().subtract(INITIAL_NUMBER_OF_DAYS_FOR_SEARCH, 'day').format("yyyy-MM-DD"), businessValueList: [], businessKeyInfo: messageTypeBussinessKeyMap[newMessageType].join(' || ')}]);
    }

    const deleteRow = (index, retryMessageType) => {
        selectedMessageTypesList.splice(index, 1);
        setSelectedMessageTypesList([...selectedMessageTypesList]);
    }

    const previewFailedMessages = async () => {
        setShowSpinner(true)
        console.log("selectedMessageTypesList :" + JSON.stringify(selectedMessageTypesList));
        await SearchService.searchFailedSelectiveMessages(selectedMessageTypesList).then(
            (response) => {
                if(response.status === 204){
                    toast.error(NODE_INTG_MESSAGES.NO_RESULTS_FOUND);
                    setShowReviewModal(false);
                    processAllSelectedMessageTypeMap([]);
                }else if(response.status === 200){
                    setShowReviewModal(true);
                    setSearchResults(response.data.objects.sort((m1, m2) => {
                        if (m1.messageType === m2.messageType){
                            return m1.messageId < m2.messageId ? -1 : 1
                        } else {
                            return m1.messageType < m2.messageType ? -1 : 1
                        }
                    }))
                    processAllSelectedMessageTypeMap(response.data.objects);
                }
            }).catch(
            (response) => {
                toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
            });
        setShowSpinner(false);
    }

    const retryFailedMessages = async () => {
        setShowSpinner(true);

        selectedMessageTypesList.forEach((messageTypeRequest) => {
            selectedMessageMap.forEach((value, key) => {
                if (!messageTypeRequest.selectedMessageIdList) {
                    messageTypeRequest.selectedMessageIdList = [];
                }
                messageTypeRequest.selectedMessageIdList = [...messageTypeRequest.selectedMessageIdList, ...value];
            });
        })
        await RetryService.selectedRetryAction(selectedMessageTypesList).then(
            (response) => {
                if(response.status === 200){
                    toast.success(NODE_INTG_MESSAGES.MULTIPLE_MESSAGES_RETRY_SUCCESS);
                }
            }).catch(
            (response) => {
                toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
            });
        setShowSpinner(false)
        setShowReviewModal(false);
        setSelectedMessageMap(new Map());
        setSelectAllCheckBox(true);
    }

    const handleSelectAllCheckBox = () => {
        if(selectAllCheckBox){
            setSelectedMessageMap(new Map());
        }else{
            processAllSelectedMessageTypeMap(searchResults);
        }
        setSelectAllCheckBox(!selectAllCheckBox)
        for(let i = 0; i < searchResults.length; i++) {
            searchResults[i].selected = !selectAllCheckBox
        }
        setSearchResults([...searchResults].sort((m1, m2) => {
            if (m1.messageType === m2.messageType){
                return m1.messageId < m2.messageId ? -1 : 1
            } else {
                return m1.messageType < m2.messageType ? -1 : 1
            }
        }));
    }

    const processAllSelectedMessageTypeMap = (searchResults) => {
        for(let i = 0; i < searchResults.length; i++) {
            let messageIdList = selectedMessageMap.get(searchResults[i].messageType);
            if(messageIdList === undefined){
                messageIdList = [searchResults[i].messageId];
                selectedMessageMap.set(searchResults[i].messageType, messageIdList);
            }else{
                messageIdList = [...messageIdList, searchResults[i].messageId];
                selectedMessageMap.set(searchResults[i].messageType, messageIdList);
            }
            setSelectedMessageMap(selectedMessageMap);
        }
        console.log(selectedMessageMap);
    }

    const handleCheckBox = (retryMessage) => {
        let updatedSearchResults = searchResults.filter(searchResult => searchResult.messageId !== retryMessage.messageId);
        retryMessage.selected = !retryMessage.selected;
        setSearchResults([...updatedSearchResults, retryMessage].sort((m1, m2) => {
            if (m1.messageType === m2.messageType){
                return m1.messageId < m2.messageId ? -1 : 1
            } else {
                return m1.messageType < m2.messageType ? -1 : 1
            }
        }));
        let messageIdList = selectedMessageMap.get(retryMessage.messageType);
        if(messageIdList === undefined){
            messageIdList = [];
            selectedMessageMap.set(retryMessage.messageType, messageIdList);
        }
        if(retryMessage.selected){
            messageIdList = [...messageIdList, retryMessage.messageId];
        }else{
            messageIdList = messageIdList.filter(messageId => messageId !== retryMessage.messageId);
        }
        messageIdList.length !==0 ? selectedMessageMap.set(retryMessage.messageType, messageIdList) : selectedMessageMap.delete(retryMessage.messageType);
        console.log(selectedMessageMap);
        selectedMessageMap.size === 0 ? setSelectAllCheckBox(false): setSelectAllCheckBox(true);
    }

    return (
        <div id="modal-root">
            <NikeDesignSystemProvider>
            <div className="subTitle">Selective Retry</div>
                <div>
                    {
                    selectedMessageTypesList.map((retryMessageType, index) =>
                        <div className="search-section" key={`div-outer-${index}`} style={{paddingBottom:'1%'}}>
                            <span className="search-field-span" style={{width: "32%", height: "80px"}} key={`span-1-${index}`}>
                                <Typeahead onInputChange={()=> {
                                    setShowToolTip(false);
                                    retryMessageType.messageType = '';
                                    validateDisablePreview();
                                }}
                                    key={`message-type-list-${index}`}
                                    label="Message Type*"
                                    selectedValue={retryMessageType.messageType}
                                    controlledValue={retryMessageType.messageType}
                                    noResultsMessage="Message Type List not found"
                                    searchableItems={messageTypeList}
                                    showListboxWhenEmpty={true}
                                    onItemSelect={(selectedValue) => {
                                        refreshFormData(selectedValue, index, retryMessageType);
                                        validateDisablePreview();
                                        setShowToolTip(true)
                                    }}
                                />
                                {showToolTip && <div className="tooltip">{retryMessageType.messageType}</div>}
                            </span>
                            <span className="search-field-span" style={{width: "10%", height: "80px"}} key={`span-2-${index}`}>
                                <TextFieldStyled
                                    key={`message-node-list-${index}`}
                                    label="Node"
                                    type="text"
                                    value={retryMessageType.messageNode}
                                    onChange={(event) => {
                                        if ( retryMessageType.messageNode!==event.target.value) {
                                        retryMessageType.messageNode = event.target.value;
                                        validateDisablePreview();
                                        }

                                    }}
                                />
                            </span>

                            <span className="search-field-span" style={{ width: '9%', height: "80px" }} key={`span-4-${index}`}>
                                    <Typeahead onInputChange={() => { 
                                        retryMessageType.messageStatus = '' ;
                                        validateDisablePreview();
                                    }}
                                        id="status"
                                        label="Status*"
                                        controlledValue={retryMessageType.messageStatus}
                                        noResultsMessage="Message Type not found"
                                        searchableItems={["all", "success", "failed", "filtered"]}
                                        showListboxWhenEmpty={true}
                                        onItemSelect={(selectedValue) => {
                                            if (selectedValue !== retryMessageType.messageStatus) {
                                                retryMessageType.messageStatus = selectedValue;
                                                validateDisablePreview();
                                            }
                                        }}
                                    />
                                </span>
                            <span className="search-field-span" style={{width:"13%", height: "80px"}}>
                                <DatePickerStyled
                                    name="Search window from date"
                                    id="search-from-date"
                                    label="From"
                                    placeholder="From"
                                    error={retryMessageType.hasDateError}
                                    errorMessage={retryMessageType.dateErrorMessage}
                                    autoComplete="off"
                                    value={retryMessageType.fromDate}
                                    shouldUseFallback={false}
                                    iconTitle="Calendar Icon"
                                    datepickerfallbackprops={{monthPlaceholder:"mm",dayPlaceholder:"dd",yearPlaceholder:"yyyy",a11yMonthLabel:"Month",a11yDayLabel:"Day",a11yYearLabel:"Year",dateSeparator:"/"}}
                                    onChange={(event) => {
                                        retryMessageType.fromDate = event.target.value;
                                        validateDate(retryMessageType);
                                        validateDisablePreview();
                                    }}
                                />
                            </span>
                            <span className="search-field-span" style={{width:"13%", height: "80px"}}>
                                <DatePickerStyled
                                    name="Search window to date"
                                    id="search-to-date"
                                    label="To"
                                    placeholder="To"
                                    error={retryMessageType.hasDateError}
                                    errorMessage={retryMessageType.dateErrorMessage}
                                    autoComplete="off"
                                    shouldUseFallback={false}
                                    iconTitle="Calendar Icon"
                                    datepickerfallbackprops={{monthPlaceholder:"mm",dayPlaceholder:"dd",yearPlaceholder:"yyyy",a11yMonthLabel:"Month",a11yDayLabel:"Day",a11yYearLabel:"Year",dateSeparator:"/"}}
                                    ariaLive="polite"
                                    value={retryMessageType.toDate}
                                    onChange={(event) => {
                                        retryMessageType.toDate = event.target.value;
                                        validateDate(retryMessageType);
                                        validateDisablePreview();
                                    }}
                                />
                            </span>
                            <span className="search-field-span" style={{width: "33%", height: "80px"}} key={`span-3-${index}`}>
                                <div style={{display: "flex"}} key={`div-inner-${index}`}>
                                    <span style={{width: "90%"}} key={`span-inner-1-${index}`}>
                                        <TextFieldStyled
                                            key={`bussiness-id-${index}`}
                                            value={retryMessageType.businessValueList}
                                            type="text"
                                            error={retryMessageType.hasBusinessValueError}
                                            requiredIndicator="*"
                                            placeholder="Comma Separated Business Values"
                                            name="businessValueField"
                                            test-id="business-test-id"
                                            id={`bussiness-${index}`}
                                            label="Business Key"
                                            onChange={(event) => {
                                                retryMessageType.businessValueList = event.target.value === '' ? [] : event.target.value.split(",");
                                                validateBusinessValue(retryMessageType)
                                                validateDisablePreview();
                                            }}
                                        />
                                    </span>
                                    <span style={{width: "5%", height: "80px", paddingRight:"20px"}} key={`span-inner-2-${index}`}>
                                        <Tooltip key={`tooltip-${index}`} message={retryMessageType.businessKeyInfo}
                                                 position='left' size="large"><Icon size="s" type="Info"/></Tooltip>
                                    </span>
                                </div>
                            </span>
                        </div>
                    )
                    }
                </div>
                <div className={styling.searchSection} style={{ paddingTop: '1%'}}>
                    <span >
                          <IconButton
                              type="Preview"
                              iconSize="m"
                              disabled={disablePreview}
                              onClick={previewFailedMessages}
                              disabledTip={{
                                  message: "disableMessage",
                                  position: "right",
                                  size: "medium",
                              }}
                          >
                              Preview
                        </IconButton>
                    </span>   
                    <span style={{ marginLeft:'1%'}}>
                        <IconButton
                                type="CaretLeft"
                                iconSize="m"
                                onClick={()=>props.backButtonHandler(true)}
                            >
                          Back
                        </IconButton>
                    </span>
                </div>
                <PageSpinner show={showSpinner} />
                <div id="div-dialog-id">
                    <DialogStyled
                        onClose={() => {
                            setShowReviewModal(false);
                            setSelectedMessageMap(new Map());
                            }
                        }
                        isOpen={showReviewModal}
                        appearance="fullscreen"
                        title=""
                        id="dialog-id"
                        dialogContainerCompProps={{style:{width: '97%'}}}
                    >
                        <div className="details-section" style={{paddingBottom:'25px'}}>
                            <span className="details-field">
                                <h2>Message Details</h2>
                            </span>
                            <span className="details-field">
                                <Button
                                    small="true"
                                    className="button"
                                    onClick={() => {
                                        setShowReviewModal(false);
                                        setSelectedMessageMap(new Map());
                                        setSelectAllCheckBox(true);
                                    }}
                                >
                                Close
                                </Button>
                            </span>
                            <span className="details-field">
                                <Button
                                    small="true"
                                    className="button"
                                    onClick={retryFailedMessages}
                                    disabled={!selectAllCheckBox}
                                >
                                Retry
                                </Button>
                            </span>
                        </div>
                        <div className="details-section-without-padding" >
                            <TableContainer
                                className="table table-hover"
                            >
                                <Thead style={{display:"block"}}>
                                    <Tr>
                                        <Th className="col-1 columnWithoutPadding" >
                                            <Checkbox
                                                id="checkbox-all"
                                                checked={selectAllCheckBox}
                                                onChange={handleSelectAllCheckBox}
                                            />
                                        </Th>
                                        {failedMessageDetailsColumns.map((headerItem, headerIndex) => (
                                            <Th
                                                key={headerIndex}
                                                className={`columnWithoutPadding col-${headerItem.columnCount}`} 
                                            >
                                               {headerItem.Header}
                                            </Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody style={{height:"500px", overflowX:"auto", overflowY:"auto", display:"block"}}>
                                    {searchResults.map((dataItem, index) => (
                                        <Tr key={`tr-${index}`}>
                                            <Td className="col-1 columnWithoutPadding" >
                                                <Checkbox
                                                    id={`checkbox-${index}`}
                                                    checked={dataItem.selected}
                                                    onChange={() => {handleCheckBox(dataItem)}}
                                                />
                                            </Td>
                                            {failedMessageDetailsColumns.map((head, headIndex) => (
                                                (headIndex<4) &&
                                                <Td 
                                                    key={`trc-${head.accessor}-${headIndex}`}
                                                    className={`td-compact-table-cell-padding columnWithoutPadding col-${head.columnCount}`}
                                                >
                                                
                                                    { (dataItem[head.accessor] !== undefined && dataItem[head.accessor].length>37) 
                                                      ? <a className={'anchor_tag'}  href="#" title={dataItem[head.accessor]} alt={dataItem[head.accessor]} > {dataItem[head.accessor].substring(0, 36)}...</a> 
                                                       : dataItem[head.accessor].toString()
                                                    }
                                                </Td>
                                            ))}
                                            {failedMessageDetailsColumns.map((head, headIndex) => (
                                                (headIndex===4) &&
                                                <Td 
                                                    key={`trc-${head.accessor}-${headIndex}`}
                                                    className={`td-compact-table-cell-padding columnWithoutPadding col-${head.columnCount}`}
                                                >
                                                     { dataItem[head.accessor] !== undefined ? dataItem[head.accessor].substr(0,25).padEnd(40," .").toString(): "NA" }
                                                    
                                                     {(dataItem[head.accessor] !== undefined) ? <Tooltip key={`tooltip-${index}`} message={dataItem[head.accessor]} position='left' size="large"><Icon size="s" type="Info"/></Tooltip> : "" }
                                                  
                                                </Td>
                                            ))}
                                            {failedMessageDetailsColumns.map((head, headIndex) => (
                                                (headIndex>4) &&
                                                <Td
                                                    key={`trc-${head.accessor}-${headIndex}`}
                                                    className={`td-compact-table-cell-padding columnWithoutPadding col-${head.columnCount}`}
                                                >
                                                    {dataItem[head.accessor].toString()}
                                                </Td>
                                            ))}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </TableContainer>
                        </div>
                    </DialogStyled>
                </div>
            </NikeDesignSystemProvider>
        </div>
);
};
export default MultipleMessageRetry
