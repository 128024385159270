import {
    DatePickerStyled,
    NikeDesignSystemProvider,
    TextAreaStyled,
    TextFieldStyled,
    Typeahead,
} from "@nike/nike-design-system-components";
import {IconButton, PageSpinner, Tooltip, Icon} from "@nike/epic-react-ui";
import React, { useState, useEffect } from "react";
import moment from "moment";
import {CommonService} from "../services/service.common"
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "./styles/search.css";
import "@nike/epic-react-ui/dist/styles/main.css";
import NODE_INTG_MESSAGES from "../constants/app.constants";
import styling from './styles/SuccessMessageRetry.module.css';
import { RetryService } from "../services/service.retry";

const  INITIAL_NUMBER_OF_DAYS_FOR_SEARCH = 7;
const  MAX_ALLOWED_DAYS_FOR_SEARCH = 90;

function SuccessMessageRetry(props) {

        const [businessValue , setBusinessValue] = useState("");
        const [businessValueError , setBusinessValueError] = useState(false);
        const [messageType , setMessageType] = useState("");
        const [fromDate , setFromDate] = useState("");
        const [toDate , setToDate] = useState("");
        const [node , setNode] = useState("");
        //const [messageNodeList, setMessageNodeList] = useState([]);
        //const [messageNodeMap, setMessageNodeMap] = useState({});
        const [messageTypeList, setMessageTypeList] = useState([]);
        const [messageTypeMap, setMessageTypeMap] = useState({});
        const [businessKeys, setBusinessKeys] = useState([]);
        const [disableButton, setDisableButton] = useState(true);
        const [hasErrors, setHasErrors] = useState(false);
        const [disableMessage, setDisableMessage] = useState(NODE_INTG_MESSAGES.INVALID_INPUT_RETRY);
        const [showSpinner , setShowSpinner] = useState(false);
        const [showToolTip, setShowToolTip] = useState(true);
    
        const [status, setStatus] = useState("all");

        const resetState = () => {
            setDisableButton(false);
            setHasErrors(false);
        }
    
    
        useEffect(() => {
            setFromDate(moment().subtract(INITIAL_NUMBER_OF_DAYS_FOR_SEARCH, 'day').format("yyyy-MM-DD"))
            setToDate(moment().format("yyyy-MM-DD"))
        }, []);
    
        useEffect( () => {
            async function messageTypeLoad() {
                setShowSpinner(true)
                let response = await CommonService.getMessageTypeInformation();
                response.data.messageTypes['All'] = [];
                setMessageTypeMap(response.data.messageTypes);
                setMessageTypeList(Object.keys(response.data.messageTypes).sort());
                setMessageType(Object.keys(response.data.messageTypes).length >0 ? Object.keys(response.data.messageTypes).sort()[0] : "");
                setBusinessKeys(response.data.messageTypes[Object.keys(response.data.messageTypes).sort()[0]].join(' || '));
                setShowSpinner(false)
            }
            messageTypeLoad();
        }, []);
    
        /*
        useEffect( () => {
            async function messageNodeLoad() {
                let response = await CommonService.getMessageNodeInformation();
                response.data.messageTypes['All'] = [];
                setMessageNodeMap(response.data.messageTypes);
                let nodeList =(response.data.messageTypes)[Object.keys(response.data.messageTypes).sort()[0]] ;
                setMessageNodeList(nodeList);
                setNode(nodeList.length > 0 ? nodeList[0] : null)
            }
            messageNodeLoad();
        }, []);
        */

        useEffect( () => {
            validateSearchCriteria(toDate, fromDate, status, messageType, businessValue);
        }, [toDate, fromDate, status, messageType, businessValue]);
    
        useEffect( () => {
            validateBusinessKey(businessValue);
        }, [businessValue]);
        
        
    const validateSearchCriteria = (toDateValue, fromDateValue, statusValue, messageTypeValue, tempBusinessValue) => {
        let isValidationFailed =  false;   
        if((fromDateValue === '' && toDateValue !== '') || (toDateValue < fromDateValue) || parseInt((new Date(toDateValue) - new Date(fromDateValue)) / (1000 * 60 * 60 * 24), 10) > MAX_ALLOWED_DAYS_FOR_SEARCH ){
                setHasErrors(true);
                isValidationFailed =  true;  
            }else{
                setHasErrors(false);
            }
            if (statusValue === '' || messageTypeValue === '' || tempBusinessValue.length === 0) {
                isValidationFailed =  true;  
            }

            setDisableButton(isValidationFailed)
           
    }
    
    const validateBusinessKey = (businessValue) => {
        if(businessValue.length === 0){
            setBusinessValueError(true);
        }else{
            setBusinessValueError(false);
        }
           
    }
    
        const triggerRetry = async () => {
            setDisableButton(true)
            setDisableMessage(NODE_INTG_MESSAGES.REQUEST_IN_PROGRESS)
            toast.success(NODE_INTG_MESSAGES.SUCCESS_MESSAGE_RETRY_TRIGGER_SUCCESS);
            let payload = {
                messageType: messageType,
                businessValueList: businessValue,
                messageNode: node,
                toDate: toDate,
                fromDate: fromDate,
                messageStatus: status
            };

            console.log(payload);
    
            await RetryService.successMessageRetry(payload).then(
                (response) => {
                    setDisableButton(false)
                    console.log(response.data);
                    if(response.status === 200){
                        toast.success("job id:"+response.data.jobId+",check status in Job Report Page");
                    }
                }).catch(
                (response) => {
                    setDisableButton(false)
                    toast.error(NODE_INTG_MESSAGES.TOAST_ERROR_MESSAGE);
                });
        }
    
    const validateBusinessValue = (value) => {
        setBusinessValue(value); 
        if (value.length === 0 || value.length > 1000) {
             setBusinessValueError(true);
        } else {
            setBusinessValueError(false);
        }
    }
    
    
    
        return (
            <div id="modal-root" className="bg-height">
                <NikeDesignSystemProvider value={messageTypeMap}>  
                <div className={styling.subTitle}>Successful Message Retry</div>
                    <div className={styling.searchControle}>
                        <span className="search-field-span" style={{width:'25%'}}>
                            <Typeahead onInputChange={() => {
                                    setShowToolTip(false);
                                    setMessageType('');
                                }}
                                id="message-type-list"
                                label="Message Type*"
                                selectedValue={messageType}
                                controlledValue={messageType}
                                noResultsMessage="Message Type List not found"
                                searchableItems={messageTypeList}
                                showListboxWhenEmpty={true}
                                onItemSelect={ (selectedValue) => {
                                    resetState();
                                    setBusinessKeys(messageTypeMap[selectedValue].join(' || '));
                                    //setMessageNodeList(messageNodeMap[selectedValue]);
                                    //setNode(messageNodeMap[selectedValue][0]);
                                    setMessageType(selectedValue);
                                    setShowToolTip(true);
                                }}
                            />
                            {showToolTip && <div className="tooltip">{messageType}</div>}
                        </span>
                        <span className="search-field-span" style={{width:'8%'}}>
                        <TextFieldStyled
                            value={node}
                            type="text"
                            //error={nodeValueError}
                            name="node"
                            test-id="node-test-id"
                            id="node"
                            label="Node"
                            onChange={(event) => {
                                resetState();
                                setNode(event.target.value)
                            }}
                        />

                        </span> 
                        <span className="search-field-span" style={{ width: '9%' }}>
                            <Typeahead onInputChange={() => setStatus('')}
                                id="status"
                                label="Status*"
                                controlledValue={status}
                                noResultsMessage="Message Type not found"
                                searchableItems={["all", "success", "failed", "filtered"]}
                                showListboxWhenEmpty={true}
                                onItemSelect={(selectedValue) => {
                                    if (selectedValue !== status) {
                                        resetState();
                                        setStatus(selectedValue)
                                    }
                                }}
                            />
                        </span>
                        <span className="search-field-span" style={{width:'13%'}}>
                            <DatePickerStyled
                                name="Search window from date"
                                id="search-from-date"
                                label="From"
                                placeholder="From"
                                error={hasErrors}
                                errorMessage= {"Date Range should be " + MAX_ALLOWED_DAYS_FOR_SEARCH + " days"}
                                autoComplete="off"
                                value={fromDate}
                                shouldUseFallback={false}
                                iconTitle="Calendar Icon"
                                datepickerfallbackprops={{monthPlaceholder:"mm",dayPlaceholder:"dd",yearPlaceholder:"yyyy",a11yMonthLabel:"Month",a11yDayLabel:"Day",a11yYearLabel:"Year",dateSeparator:"/"}}
                                onChange={(event) => {
                                    resetState();
                                    setFromDate(event.target.value)
                                }}
                            />
                        </span>
                        <span className="search-field-span" style={{width:'13%'}}>
                            <DatePickerStyled
                                name="Search window to date"
                                id="search-to-date"
                                label="To"
                                placeholder="To"
                                error={hasErrors}
                                errorMessage= {"Date Range should be " + MAX_ALLOWED_DAYS_FOR_SEARCH + " days"}
                                autoComplete="off"
                                shouldUseFallback={false}
                                iconTitle="Calendar Icon"
                                datepickerfallbackprops={{monthPlaceholder:"mm",dayPlaceholder:"dd",yearPlaceholder:"yyyy",a11yMonthLabel:"Month",a11yDayLabel:"Day",a11yYearLabel:"Year",dateSeparator:"/"}}
                                ariaLive="polite"
                                value={toDate}
                                onChange={(event) => {
                                    resetState();
                                    setToDate(event.target.value)
                                }}
                            />
                        </span>
                    
                        <span className="search-field-span" style={{ width: '25%' }}>
                        <div style={{ display: "flex" }}>
                            <span style={{ width: '100%' }}>
                            <TextAreaStyled
                                name="business keys"
                                id="business-key"
                                type="text"
                                requiredIndicator="*"
                                placeholder="Comma Separated Business Keys"
                                errorMessage="Business Keys should be greater than 0 or less than 1000"
                                value={businessValue} 
                                error={businessValueError}
                                    onChange={(event) => {
                                        validateBusinessValue(event.target.value === '' ? [] : event.target.value.split(","))                                        
                                    }}
                                
                            />
                            </span>

                            <span>
                            <Tooltip className={styling.tooltip} message={businessKeys} position='right'><Icon size="s" type="Info"/></Tooltip>
                            </span>
                        </div>
                    </span>
                    </div>
                
                    <div className={styling.searchSection} style={{ paddingTop: '1%'}}>
                       
                        <span style={{ marginRight:'1%'}}>
                            <IconButton
                                type="Repeat"
                                iconSize="m"
                                disabled={disableButton}
                                onClick={triggerRetry}
                                disabledTip={{
                                    message: disableMessage,
                                    position: "right",
                                    size: "medium",
                                }}
                                theme={{
                                    primary: !disableButton ? "#000000" : "#e5e5e5",
                                    secondary: !disableButton ? "#ffffff" : "#757575",
                                }}
                            >
                                Retry
                                </IconButton>
                        </span>   
                        <span>   
                                <IconButton
                                    type="CaretLeft"
                                    iconSize="m"
                                    onClick={()=>props.backButtonHandler(true)}  
                                >
                            Back
                                </IconButton>
                        </span>
                    </div>
                <PageSpinner show={showSpinner} />
                
            </NikeDesignSystemProvider>
            </div>
    );
}

export default SuccessMessageRetry;