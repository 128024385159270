import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service.base";
import { globalHeaders } from "../utilities/http";

function searchMessages(payload, searchHeaders) {
    return baseService.post(
        API_INTERFACE_URI.MESSAGE_SEARCH_PATH,
        Object.assign(globalHeaders, searchHeaders),
        payload
    );
}

function failedMessageCount(payload) {
    return baseService.post(
        API_INTERFACE_URI.FAILURE_COUNT_PATH,
        globalHeaders,
        payload
    );
}

function searchFailedSelectiveMessages(payload) {
    return baseService.post(
        API_INTERFACE_URI.FAILED_MESSAGE_SELECTIVE_SEARCH_PATH,
        globalHeaders,
        payload
    );
}

export const SearchService = {
    searchMessages,
    failedMessageCount,
    searchFailedSelectiveMessages,
}
