import React from "react";
import "../containers/styles/search.css";
function Footer() {
  return (
    <div className="footer">
      <span className="epic-legal-text">
        © 2022 Nike, Inc. All Rights Reserved
      </span>
    </div>
  );
}

export default Footer;
